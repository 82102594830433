// Client Store

import { getField, updateField } from 'vuex-map-fields'
import { isEmpty, cloneDeep } from 'lodash'

const client = {
  admin_handle: {},
  billing_handle: {},
  contact_handle: {
    type: 'PERS',
    sex: null,
    firstname: null,
    lastname: null,
    organisation: null,
    street: null,
    number: null,
    city: null,
    zip: null,
    country: null,
    phone: null,
    fax: null,
    email: null,
    unique_name: null,
  },
  customer: null,
  id: null,
  paymenttypes: null,
  pricelist_id: null,
  reversecharge: false,
  vatid: null,
  vatoverride: null,
}

const state = {
  client: cloneDeep(client),
  errors: {},
  foundClients: [],
  options: null,
  pending: false,
  pendingOptions: false,
  pendingSave: false,
}

const getters = {
  isUnique: ({ client }) => client && client.contact_handle && !isEmpty(client.contact_handle.unique_name),
  getField,
}

const actions = {
  getClient({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_CLIENT_SUCCESS', data.client)
          resolve()
        })
        .catch(error => {
          commit('GET_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditClient({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_CLIENT_SUCCESS', data.client)
          resolve()
        })
        .catch(error => {
          commit('GET_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getOptions({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGOPTIONS', true)

      this.api.item
        .getOptions(type)
        .then(({ data }) => {
          commit('GET_CLIENT_OPTIONS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_CLIENT_OPTIONS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGOPTIONS', false)
        })
    })
  },
  createClient({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      delete state.client.billing_handle
      delete state.client.admin_handle

      this.api.item
        .addItem(type, state.client)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_CLIENT_FAIL', data.errors)
            return
          }
          commit('ADD_CLIENT_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editClient({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      delete state.client.billing_handle
      delete state.client.admin_handle

      this.api.item
        .editItem(type, state.client)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_CLIENT_FAIL', data.errors)
            return
          }
          commit('EDIT_CLIENT_SUCCESS', data.client)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  searchClient({ commit }, searchString) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.client
        .searchClient(searchString)
        .then(({ data }) => {
          commit('SEARCH_CLIENT_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('SEARCH_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  clearClient({ commit }) {
    commit('CLEAR_CLIENT')
  },
  clearFoundClients({ commit }) {
    commit('CLEAR_FOUND_CLIENTS')
  },
}

const mutations = {
  CLEAR_CLIENT(state) {
    state.client = cloneDeep(client)
    state.errors = {}
    state.options = null
  },
  CLEAR_FOUND_CLIENTS(state) {
    state.foundClients = []
    state.errors = {}
  },
  GET_CLIENT_SUCCESS(state, client) {
    state.errors = {}
    state.client = client
  },
  GET_CLIENT_FAIL(state, errors) {
    state.errors = errors
  },
  GET_CLIENT_OPTIONS_SUCCESS(state, data) {
    state.options = data
  },
  GET_CLIENT_OPTIONS_FAIL(state, errors) {
    state.options = false
  },
  ADD_CLIENT_SUCCESS(state) {
    state.errors = {}
  },
  ADD_CLIENT_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_CLIENT_SUCCESS(state, client) {
    state.errors = {}
  },
  EDIT_CLIENT_FAIL(state, errors) {
    state.errors = errors
  },
  SEARCH_CLIENT_SUCCESS(state, data) {
    if (data !== null) {
      state.foundClients = data.clients
    } else {
      state.foundClients = []
    }
  },
  SEARCH_CLIENT_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
  'pendingOptions',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
