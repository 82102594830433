import Vue from 'vue'

import { sync } from 'vuex-router-sync'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueDarkMode from '@vue-a11y/dark-mode'
import VueCompositionAPI from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

import store from './store'
import router from './router'

import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import { i18n, setI18nLanguage } from './plugins/i18n'

import App from './App'

sync(store, router)

Vue.use(require('vue-moment'))
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueDarkMode)
Vue.use(VueCompositionAPI)
Vue.use(VueApexCharts)

Vue.component('Apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.prototype.setI18nLanguage = setI18nLanguage

store.dispatch('auth/initAuth')

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
})
