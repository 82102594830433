<template>
  <v-app>
    <AppDrawer v-if="toggleView" />

    <AppToolbar v-if="toggleView" />

    <AppView />

    <AppToasts />
  </v-app>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'App',
  components: {
    AppDrawer: _ => import('@/components/core/AppDrawer'),
    AppToolbar: _ => import('@/components/core/AppToolbar'),
    AppView: _ => import('@/components/core/AppView'),
    AppToasts: _ => import('@/components/core/AppToasts'),
  },
  setup(props, { root: { setI18nLanguage }, root }) {
    const toggleView = computed(_ => {
      if (process.env.NODE_ENV !== 'production') console.log(root.$route.name)
      return root.$route.name !== 'login'
    })

    setI18nLanguage(process.env.VUE_APP_I18N_FALLBACK_LOCALE)

    return {
      toggleView,
    }
  },
})
</script>

<style lang="scss">
@import 'styles/index.scss';
</style>
