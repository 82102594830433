<template>
  <AppCard :title="$t('Logbox')" card-text-classes="text-center">
    <h3 class="text-h5">
      <v-icon>mdi-information-outline</v-icon> Log Box
      <v-icon>mdi-alert-octagon</v-icon>
    </h3>
    <pre v-html="syntaxHighlight(log)" />
  </AppCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'

export default defineComponent({
  name: 'LogBox',
  components: {
    AppCard,
  },
  props: {
    log: {
      type: Object,
      default: _ => {},
    },
  },
  setup() {
    const syntaxHighlight = json => {
      json = JSON.stringify(json, undefined, 2)
      json = json
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
        function(match) {
          let cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        },
      )
    }

    return {
      syntaxHighlight,
    }
  },
})
</script>


<style lang="scss" scoped>
::v-deep {
  pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
  }
  .string {
    color: green;
  }
  .number {
    color: darkorange;
  }
  .boolean {
    color: blue;
  }
  .null {
    color: magenta;
  }
  .key {
    color: red;
  }
}
</style>
