<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <v-card-title class="v-card--material__header" flat>
      <slot v-if="!title && !text" name="header" />
      <v-row v-else wrap align-center>
        <v-col>
          <h2 class="title font-weight-light mb-0" v-text="title" />
          <p v-if="text" class="category font-weight-thin mt-2" v-text="text" />
        </v-col>
        <v-col v-if="titleActions || prevRoute" class="text-right">
          <slot name="titleActions" />
          <v-btn v-if="prevRoute" :to="prevRoute" color="secondary" fab small>
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text :class="cardTextClasses">
      <v-col v-if="loading" text-center>
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <slot v-else />
    </v-card-text>

    <v-divider v-if="$slots.actions" class="mx-3" />

    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    titleActions: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: undefined,
    },
    cardTextClasses: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    prevRoute: {
      type: String,
      default: null,
    },
  },
})
</script>
