import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/api'

// Modules
import app from './modules/app'
import auth from './modules/auth'
import categories from './modules/categories'
import category from './modules/category'
import client from './modules/client'
import clients from './modules/clients'
import domain from './modules/domain'
import domains from './modules/domains'
import handle from './modules/handle'
import handles from './modules/handles'
import invoice from './modules/invoice'
import invoices from './modules/invoices'
import notifications from './modules/notifications'
import product from './modules/product'
import products from './modules/products'
import quote from './modules/quote'
import quotes from './modules/quotes'
import subscription from './modules/subscription'
import subscriptions from './modules/subscriptions'
import user from './modules/user'
import users from './modules/users'
import voucher from './modules/voucher'
import vouchers from './modules/vouchers'
import zone from './modules/zone'
import zones from './modules/zones'

Vuex.Store.prototype.api = api

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {}
const actions = {}
const mutations = {}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    app,
    auth,
    categories,
    category,
    client,
    clients,
    domain,
    domains,
    handle,
    handles,
    invoice,
    invoices,
    notifications,
    product,
    products,
    quote,
    quotes,
    subscription,
    subscriptions,
    user,
    users,
    voucher,
    vouchers,
    zone,
    zones,
  },
  strict: debug,
})
