<template>
  <v-container fluid class="pt-10">
    <v-row>
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OverviewWrapper',
})
</script>
