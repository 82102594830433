<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Create User')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <SearchSelectClient v-model="client_id" rules="required" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="role"
                      rules="required"
                      label="Role"
                      :items="roles"
                      menu-props="offset-y"
                      placeholder="Select Role"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="firstname"
                      rules="required"
                      label="Firstname"
                      name="firstname"
                      placeholder="Enter Firstname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="lastname"
                      rules="required"
                      label="Lastname"
                      name="lastname"
                      placeholder="Enter Lastname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="username"
                      rules="required"
                      label="Username"
                      name="username"
                      placeholder="Enter Username"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="password"
                      type="password"
                      rules="required"
                      label="Password"
                      placeholder="Enter Password"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="email"
                      type="email"
                      rules="required|email"
                      label="E-Mail"
                      name="email"
                      placeholder="Enter E-Mail"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { roles } from '@/config'
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateUser',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    SearchSelectClient,
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Users')

    // store
    const pending = computed(_ => $store.state.user.pending)
    const errors = computed(_ => $store.state.user.errors)
    const foundClients = computed(_ => $store.state.client.foundClients)
    const createUser = _ => $store.dispatch('user/createUser')
    const clearUser = _ => $store.dispatch('user/clearUser')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createUser().then(({ user, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Nutzer erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'users-item',
            params: { id: user.id },
          })
        } else {
          $router.push({ path: '/Users' })
        }
      })
    }

    onUnmounted(_ => {
      clearUser()
    })

    return {
      prevRoute,
      roles,
      pending,
      foundClients,
      errors,
      submitForm,
      ...mapFields('user', [
        'user.firstname',
        'user.lastname',
        'user.username',
        'user.password',
        'user.email',
        'user.role',
        'user.client_id',
      ]),
    }
  },
})
</script>
