<template>
  <v-dialog v-model="opened" :max-width="maxWidth">
    <v-card dense tile>
      <v-card-title class="px-4">
        <slot name="title" />
        <v-spacer />
        <v-btn text icon color="black" @click="opened = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-4">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const opened = computed({
      get: _ => props.isOpen,
      set: _ => emit('onCloseDialog'),
    })

    return {
      opened,
    }
  },
})
</script>
