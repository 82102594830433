const state = {
  domains: [],
  pending: false,
  pagination: {},
  errors: {},
}

const getters = {}

const actions = {
  getDomains({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_DOMAINS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_DOMAINS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeDomain({ commit, rootState }, domainId) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, domainId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_DOMAIN_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_DOMAIN_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_DOMAIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  GET_DOMAINS_SUCCESS(state, { pagination, domains }) {
    state.errors = {}
    state.pagination = pagination
    state.domains = domains
  },
  GET_DOMAINS_FAIL(state, errors) {
    state.errors = errors
  },
  REMOVE_DOMAIN_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_DOMAIN_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
