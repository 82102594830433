<template>
  <v-row class="quote_product-row" wrap>
    <v-col cols="6" sm="2" md="1">
      <SearchSelectByArtNr
        v-if="!quoteProduct.isDirty"
        :key="`nr-${index}`"
        v-model="quoteProduct.artNr"
        :vid="`nr-${index}`"
        @input="setArtNr"
      />
      <VTextFieldWithValidation
        v-else
        v-model="quoteProduct.artNr"
        label="Nr"
        name="nr"
        :vid="`nr-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Nr"
      />
    </v-col>

    <v-col cols="6" sm="2" md="2">
      <SearchSelectByTitle
        v-if="!quoteProduct.isDirty"
        :key="`title-${index}`"
        v-model="quoteProduct.title"
        :vid="`title-${index}`"
        @input="setTitle"
      />

      <VTextFieldWithValidation
        v-else
        v-model="quoteProduct.title"
        label="Title"
        name="title"
        :vid="`title-${index}`"
        rules="required"
        placeholder="Title"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.description"
        label="Description"
        name="description"
        :vid="`description-${index}`"
        placeholder="Enter Description"
      />
    </v-col>

    <v-col cols="6" sm="2" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.quantity"
        label="Quantity"
        name="quantity"
        :vid="`quantity-${index}`"
        rules="required|integer|min_value:1"
        placeholder="Quantity"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="quoteProduct.amount"
        label="Amount"
        name="amount"
        :vid="`amount-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Amount"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="quoteProduct.tax"
        label="Tax"
        name="tax"
        :vid="`tax-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="4" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="quoteProduct.discount"
        label="Discount"
        name="discount"
        :vid="`discount-${index}`"
        rules="integer|min_value:0"
        placeholder="Discount"
      />
    </v-col>

    <v-col cols="2" sm="3" md="1" class="text-right">
      <div class="actions">
        <!-- // TODO: [Future Feature] Add Save Product to Productlist Button -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn class="pa-1 ml-1 mt-4 remove-button" color="error" icon v-on="on" @click="removeQuoteProduct">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Product</span>
        </v-tooltip>
      </div>
    </v-col>

    <v-col v-if="length > index + 1" cols="12">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import SearchSelectByArtNr from '@/components/Products/SearchSelectByArtNr'
import SearchSelectByTitle from '@/components/Products/SearchSelectByTitle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'QuoteProductRow',
  components: {
    SearchSelectByArtNr,
    SearchSelectByTitle,
    VTextFieldWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    quoteProduct: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    if (props.quoteProduct.artNr) {
      props.quoteProduct.isDirty = true
    }

    const setArtNr = product => {
      Object.assign(props.quoteProduct, product)
      props.quoteProduct.amount = product.price
      props.quoteProduct.isDirty = true
    }

    const setTitle = product => {
      Object.assign(props.quoteProduct, product)
      props.quoteProduct.isDirty = true
    }

    const removeQuoteProduct = _ => {
      emit('removeQuoteProduct', props.index)
    }

    return {
      setArtNr,
      setTitle,
      removeQuoteProduct,
    }
  },
})
</script>
