// Voucher Store

import { cloneDeep } from 'lodash'
import { getField, updateField } from 'vuex-map-fields'

const voucher = {
  id: null,
  amount: null,
  archived: null,
  currency: 'EUR',
  description: null,
  direction: '',
  files: [],
  handle: {},
  handles_id: null,
  name: null,
  payment_type: '',
  status: null,
  type: '',
  voucher_date: null,
  voucher_duedate: null,
  voucher_items: [],
  voucher_paydate: null,
  voucher_updated: null,
}

const state = {
  voucher: cloneDeep(voucher),
  pending: false,
  pendingSave: false,
  errors: {},
}

const getters = {
  getField,
}

const actions = {
  getVoucher({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_VOUCHER_SUCCESS', data.voucher)
          resolve()
        })
        .catch(error => {
          commit('GET_VOUCHER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getEditVoucher({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          commit('GET_VOUCHER_SUCCESS', data.voucher)
          resolve()
        })
        .catch(error => {
          commit('GET_VOUCHER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  createVoucher({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .addItem(type, state.voucher)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_VOUCHER_FAIL', data.errors)
            resolve()
          }
          commit('ADD_VOUCHER_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_VOUCHER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editVoucher({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      delete state.voucher.handle

      this.api.item
        .editItem(type, state.voucher)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_VOUCHER_FAIL', data.errors)
            resolve()
          }
          commit('EDIT_VOUCHER_SUCCESS', data.voucher)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_VOUCHER_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  addVoucherItem({ commit }) {
    commit('ADD_VOUCHER_ITEM')
  },
  updateVoucherFiles({ commit }, files) {
    commit('UPDATE_VOUCHER_FILES', cloneDeep(files))
  },
  removeVoucherItem({ commit }, index) {
    commit('REMOVE_VOUCHER_ITEM', index)
  },
  clearVoucher({ commit }) {
    commit('CLEAR_VOUCHER')
  },
}

const mutations = {
  CLEAR_VOUCHER(state) {
    state.voucher = cloneDeep(voucher)
    state.errors = {}
  },
  ADD_VOUCHER_ITEM(state) {
    state.voucher.voucher_items.push({
      type: '',
      tax: '',
      amount: null,
    })
  },
  REMOVE_VOUCHER_ITEM(state, index) {
    state.voucher.voucher_items.splice(index, 1)
  },
  GET_VOUCHER_SUCCESS(state, voucher) {
    state.errors = {}
    state.voucher = voucher
  },
  GET_VOUCHER_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_VOUCHER_SUCCESS(state) {
    state.errors = {}
  },
  ADD_VOUCHER_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_VOUCHER_SUCCESS(state, voucher) {
    state.errors = {}
    state.voucher = voucher
  },
  EDIT_VOUCHER_FAIL(state, errors) {
    state.errors = errors
  },
  UPDATE_VOUCHER_FILES(state, files) {
    state.voucher.files = files
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
