<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Vouchers')" title-actions>
      <template #titleActions>
        <v-btn to="Vouchers/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="vouchers"
        :page="pagination ? pagination.page : page"
        :items-per-page="pagination ? pagination.perPage : 12"
        :loading="pending"
        hide-default-footer
      >
        <template #[`item.direction`]="{ item }">
          <DirectionArrow :direction="item.direction" />
        </template>

        <template #[`item.client`]="{ item }">
          <router-link
            :to="{
              name: 'clients-item',
              params: { id: item.handle.clients_id },
            }"
          >
            <b v-if="item.handle.organisation">
              {{ item.handle.organisation }}
            </b>
            {{ item.handle.firstname }}
            {{ item.handle.lastname }}
          </router-link>
        </template>

        <template #[`item.voucher_date`]="{ item }">
          {{ item.voucher_date | moment('DD.MM.YYYY') }}
        </template>

        <template #[`item.amount`]="{ item }">
          <i18n-n :value="item.amount" :format="{ key: 'currency', currency: item.currency }" />
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="vouchers" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import DirectionArrow from '@/components/UI/DirectionArrow'
import OverviewActions from '@/components/UI/OverviewActions'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default {
  name: 'ViewVouchers',
  components: {
    AppCard,
    AppPagination,
    DirectionArrow,
    OverviewActions,
    OverviewWrapper,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          sortable: false,
          text: '',
          value: 'direction',
        },
        {
          sortable: false,
          text: 'Client',
          value: 'client',
        },
        {
          sortable: false,
          text: 'Type',
          value: 'type',
        },
        {
          sortable: false,
          text: 'Status',
          value: 'status',
        },
        {
          sortable: false,
          text: 'Name',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Voucher Date',
          value: 'voucher_date',
        },
        {
          sortable: false,
          text: 'Amount',
          value: 'amount',
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'right',
          width: '165px',
        },
      ],
    }
  },
  computed: {
    ...mapState('vouchers', [
      'vouchers',
      'pagination',
      'pending',
    ]),
  },
  watch: {
    '$route.query'(value) {
      this.page = value.page ? value.page : 1
      this.getVouchers(this.page)
    },
  },
  created() {
    this.page = this.$route.query.page || this.page
    this.getVouchers(this.page)
  },
  methods: {
    ...mapActions('vouchers', [
      'getVouchers',
      'removeVoucher',
    ]),
    remove(voucherId) {
      if (confirm('Sicher, dass der Beleg gelöscht werden soll?')) {
        if (this.pending) {
          return
        }
        this.removeVoucher(voucherId).then(_ => this.getVouchers(this.page))
      }
    },
  },
}
</script>
