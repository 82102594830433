// Categories Store

const state = {
  categories: [],
  pagination: {},
  pending: false,
  errors: {},
}

const getters = {}

const actions = {
  getCategories({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_CATEGORIES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_CATEGORIES_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeCategory({ commit, rootState }, categoryId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)
      const { type } = rootState.route.meta

      this.api.item
        .removeItem(type, categoryId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_CATEGORY_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_CATEGORY_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_CATEGORY_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  GET_CATEGORIES_SUCCESS(state, { pagination, categories }) {
    state.errors = {}
    state.categories = categories
    state.pagination = pagination
  },
  GET_CATEGORIES_FAIL(state, errors) {
    state.errors = errors
  },
  REMOVE_CATEGORY_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_CATEGORY_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
