<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard
          :title="$t('Personal Data / Handles')"
          :prev-route="prevRoute"
          :loading="pending"
        >
          <v-expansion-panels :value="0">
            <template v-for="{label, key} in handleTypes">
              <!-- TODO: does i need this check? -->
              <!-- <v-expansion-panel v-if="!_.isUndefined(client[`${key}_handle`])" :key="key"> -->
              <v-expansion-panel :key="key">
                <v-expansion-panel-header>
                  {{ $t(label) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <address>
                    <b v-if="domain[`${key}_handle`].organisation">{{ domain[`${key}_handle`].organisation }}</b>
                    <br />
                    <span v-if="domain[`${key}_handle`].sex == 'MALE'">Herr </span>
                    <span v-if="domain[`${key}_handle`].sex == 'FEMALE'">Frau </span>
                    {{ domain[`${key}_handle`].firstname }} {{ domain[`${key}_handle`].lastname }}<br />
                    {{ domain[`${key}_handle`].street }} {{ domain[`${key}_handle`].number }}<br />
                    {{ domain[`${key}_handle`].zip }} {{ domain[`${key}_handle`].city }}<br />
                    {{ domain[`${key}_handle`].country }}<br />
                    <b>Phone:</b> {{ domain[`${key}_handle`].phone }}<br />
                    <b>Fax:</b> {{ domain[`${key}_handle`].fax }}<br />
                    {{ domain[`${key}_handle`].email }}<br />
                    {{ domain[`${key}_handle`].unique_name }}
                  </address>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </AppCard>
      </v-col>
      <v-col v-if="!_.isUndefined(domain.zone)" cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :title="$t('Zone')">
          <ul>
            <li>id: {{ domain.zone.id }}</li>
            <li>name: {{ domain.zone.name }}</li>
            <li>master: {{ domain.zone.master }}</li>
            <li>last_check: {{ domain.zone.last_check }}</li>
            <li>type: {{ domain.zone.type }}</li>
            <li>notified_serial: {{ domain.zone.notified_serial }}</li>
            <li>active: {{ domain.zone.active }}</li>
          </ul>
        </AppCard>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :title="$t('Domain Info')">
          <div class="text--disabled">
            <p>
              <b>Domain:</b><br />
              <span>{{ domain.name }}.{{ domain.tld }}</span>
            </p>
            <p>
              <b>Created:</b><br />
              <span>{{ domain.created | moment('DD.MM.YYYY') }}</span>
            </p>
          </div>
        </AppCard>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :title="$t('Nameserver')">
          <div class="text--disabled">
            <p>
              <ul class="list-unstyled">
                <li v-if="domain.ns1">ns1: {{ domain.ns1 }}</li>
                <li v-if="domain.ns2">ns2: {{ domain.ns2 }}</li>
                <li v-if="domain.ns3">ns3: {{ domain.ns3 }}</li>
                <li v-if="domain.ns4">ns4: {{ domain.ns4 }}</li>
                <li v-if="domain.ns5">ns5: {{ domain.ns5 }}</li>
              </ul>
            </p>
          </div>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, ref, computed, onBeforeUnmount } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewDomain',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const prevRoute = ref('/Domains')
    const handleTypes = [
      {
        key: 'owner',
        label: 'Owner Handle',
      },
      {
        key: 'admin',
        label: 'Admin Handle',
      },
      {
        key: 'zone',
        label: 'Zone Handle',
      },
      {
        key: 'tech',
        label: 'Tech Handle',
      },
    ]

    // store
    const pending = computed(_ => $store.state.domain.pending)
    const domain = computed(_ => $store.state.domain.domain)
    const getDomain = _ => $store.dispatch('domain/getDomain')
    const clearDomain = _ => $store.dispatch('domain/clearDomain')

    getDomain()

    onBeforeUnmount(_ => {
      clearDomain()
    })

    return {
      pending,
      domain,
      prevRoute,
      handleTypes,
    }
  },
})
</script>
