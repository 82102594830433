<template>
  <v-container class="pt-10">
    <section class="content">
      <v-row class="align-md-stretch">
        <v-col cols="12" md="6" xl="3">
          <AppCard title="Bar Chart" class="h-100">
            <BarChart />
          </AppCard>
        </v-col>

        <v-col cols="12" md="6" xl="3">
          <AppCard title="Line Chart" class="h-100">
            <LineChart />
          </AppCard>
        </v-col>

        <v-col cols="12" md="6" xl="3">
          <AppCard title="Line Chart" class="h-100">
            <PieChart />
          </AppCard>
        </v-col>

        <v-col cols="12" md="6" xl="3">
          <AppCard title="Line Chart" class="h-100">
            <AreaChart />
          </AppCard>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import BarChart from '@/components/charts/BarChart'
import LineChart from '@/components/charts/LineChart'
import PieChart from '@/components/charts/PieChart'
import AreaChart from '@/components/charts/AreaChart'

export default defineComponent({
  name: 'ViewDashboard',
  components: {
    AppCard,
    BarChart,
    LineChart,
    PieChart,
    AreaChart,
  },
  setup(props, { root: { $store } }) {
    return {
      //
    }
  },
})
</script>
