import http from './http'

export default {
  getItems(type, page = 1) {
    return http.get(`${type}/`, {
      params: {
        page,
      },
    })
  },
}
