<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Categories')" title-actions>
      <template #titleActions>
        <v-btn to="Categories/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="categories"
        :page="pagination ? pagination.page : 1"
        :items-per-page="pagination ? pagination.perPage : 20"
        :loading="pending"
        hide-default-footer
      >
        <template #[`item.actions`]="{ item }">
          <OverviewActions type="categories" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewCategories',
  components: {
    AppCard,
    AppPagination,
    OverviewActions,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.categories.pending)
    const categories = computed(_ => $store.state.categories.categories)
    const pagination = computed(_ => $store.state.categories.pagination)
    const getCategories = page => $store.dispatch('categories/getCategories', page)
    const removeCategory = categoryId => $store.dispatch('categories/removeCategory', categoryId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getCategories(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getCategories(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass die Kategorie gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeCategory(id)
          .then(_ => {
            addToast({
              msg: 'Kategorie erfolgreich gelöscht',
              type: 'error',
            })

            getCategories(page.value)
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      categories,
      headers,
      pagination,
      remove,
    }
  },
})
</script>
