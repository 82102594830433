<template>
  <VAutocompleteWithValidation
    v-model="innerValue"
    :async-loading="loading"
    label="Client"
    :placeholder="placeholder"
    :items="foundClients"
    :search-input.sync="search"
    item-value="id"
    v-bind="$attrs"
    no-filter
    custom-item
    custom-selection
    custom-no-data
    custom-append-outer
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-title>No clients found</v-list-item-title>
      </v-list-item>
      <v-list-item @click="createNewClient">
        <v-list-item-title>Create a new client</v-list-item-title>
        <v-list-item-action class="ma-0">
          <v-btn small icon>
            <v-icon color="grey lighten-1">mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>

    <template #selection="{ item }">
      <template v-if="item._matchingData.BillingHandles.organisation">
        <small class="mr-1">({{ item._matchingData.BillingHandles.organisation }})</small>
      </template>
      {{ item._matchingData.BillingHandles.firstname }}
      {{ item._matchingData.BillingHandles.lastname }}
    </template>

    <template #item="{ item }">
      <v-list-item-content v-if="item !== null">
        <v-list-item-title>
          {{ item._matchingData.BillingHandles.firstname }}
          {{ item._matchingData.BillingHandles.lastname }}
        </v-list-item-title>
        <v-list-item-subtitle v-text="item._matchingData.BillingHandles.organisation" />
      </v-list-item-content>
      <v-list-item v-else disabled>
        <v-list-item-title>Type to search a client</v-list-item-title>
      </v-list-item>
    </template>

    <template v-if="!_.isUndefined(client) && _.isUndefined(innerValue)" #append-outer>
      <v-icon @click="reset" v-text="'mdi-history'" />
    </template>
  </VAutocompleteWithValidation>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'
import { isUndefined } from 'lodash'

import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation'

export default defineComponent({
  name: 'SearchSelectClient',
  components: {
    VAutocompleteWithValidation,
  },
  props: {
    items: {
      default: _ => [],
      type: Array,
    },
    client: {
      default: _ => {},
      type: Object,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const delayTimer = ref(null)
    const loading = ref(false)
    const search = ref(null)
    const innerValue = ref(null)

    const foundClients = computed(_ => $store.state.client.foundClients)
    const searchClient = search => $store.dispatch('client/searchClient', search)
    const clearFoundClients = _ => $store.dispatch('client/clearFoundClients')

    const placeholder = computed(_ => {
      let placeholder = 'Search Client'
      if (!isUndefined(props.client)) {
        placeholder = props.client.name
      }
      return placeholder
    })

    if (props.value) {
      innerValue.value = props.value
    }

    watch(search, val => val && searchForClient(val))
    // Handles internal model changes.
    watch(innerValue, val => emit('input', val))
    // Handles external model changes.
    watch(_ => props.value, val => innerValue.value = val)

    const reset = _ => {
      if (!isUndefined(props.client) && isUndefined(innerValue.value)) {
        clearFoundClients()
        innerValue.value = props.client.id
      }
    }

    const searchForClient = search => {
      if (search.length >= 3) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          loading.value = true
          searchClient(search)
            .then(response => {
              // if (response && response.errors) {
              //   this.errors = response.errors
              // } else {
              //   this.errors = null
              // }
              // this.loading = false
            })
            .catch(error => {
              // this.errors = error
              // this.loading = false
            })
            .finally(() => (loading.value = false))
        }, 1000)
      }
    }

    const createNewClient = _ => {
      // console.log('create new client')
    }

    onBeforeUnmount(_ => {
      clearFoundClients()
    })

    return {
      innerValue,
      loading,
      placeholder,
      foundClients,
      search,
      reset,
      createNewClient,
    }
  },
})
</script>
