<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :loading="pending" :title="$t('Edit Subscription')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <VTextFieldWithValidation label="Client" disabled :value="client" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="startdate"
                      label="Startdate"
                      name="startdate"
                      rules="required"
                      placeholder="Enter Startdate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <VTextFieldWithValidation
                      v-model="name"
                      label="Name"
                      name="name"
                      rules="required"
                      placeholder="Enter Name"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" lg="4">
                    <v-row>
                      <v-col cols="6" sm="4">
                        <VTextFieldWithValidation
                          v-model="period_amount"
                          label="Period"
                          name="period"
                          type="number"
                          min="1"
                          rules="required"
                          placeholder="Enter Period"
                        />
                      </v-col>

                      <v-col cols="6" sm="8">
                        <VSelectWithValidation
                          v-model="period_unit"
                          required
                          label="Period Unit"
                          :items="intervals"
                          menu-props="offset-y"
                          placeholder="Select Interval"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" lg="4">
                    <v-row>
                      <v-col cols="6" sm="4">
                        <VTextFieldWithValidation
                          v-model="cancelationperiod_amount"
                          label="Cancelationperiod"
                          name="period"
                          type="number"
                          min="1"
                          rules="required"
                          placeholder="Enter Period"
                        />
                      </v-col>

                      <v-col cols="6" sm="8">
                        <VSelectWithValidation
                          v-model="cancelationperiod_unit"
                          required
                          label="Period Unit"
                          :items="intervals"
                          menu-props="offset-y"
                          placeholder="Select Interval"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation label="Tenant" :value="tenant.name" disabled />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation label="Invoice Range" :value="invoicerange.name" disabled />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <template v-for="(subscription_product, key) in subscription_products">
                      <SubscriptionProductRow
                        :key="key"
                        :index="key"
                        :length="subscription_products.length"
                        :subscription-product="subscription_product"
                        @removeSubscriptionProduct="removeSubscriptionProduct"
                      />
                    </template>

                    <v-btn color="success" @click="addSubscriptionProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import { intervals } from '@/config'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import SubscriptionProductRow from '@/components/Subscriptions/SubscriptionProductRow'
import ItemWrapper from '@/layouts/ItemWrapper'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'

export default defineComponent({
  name: 'EditSubscription',
  components: {
    AppCard,
    SubscriptionProductRow,
    ItemWrapper,
    LogBox,
    DatePickerWithValidation,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Subscriptions')

    const period_amount = ref(1)
    const period_unit = ref('months')
    const cancelationperiod_amount = ref(1)
    const cancelationperiod_unit = ref('months')

    const combinedPeriod = computed(_ => {
      return `${period_amount.value} ${period_unit.value}`
    })
    const combinedCancelationperiod = computed(_ => {
      return `${cancelationperiod_amount.value} ${cancelationperiod_unit.value}`
    })

    // store
    const pending = computed(_ => $store.state.subscription.pending)
    const subscription = computed(_ => $store.state.subscription.subscription)
    const pendingSave = computed(_ => $store.state.subscription.pendingSave)
    const errors = computed(_ => $store.state.subscription.errors)
    const getEditSubscription = _ => $store.dispatch('subscription/getEditSubscription')
    const editSubscription = _ => $store.dispatch('subscription/editSubscription')
    const clearSubscription = _ => $store.dispatch('subscription/clearSubscription')
    const addSubscriptionProduct = _ => $store.dispatch('subscription/addSubscriptionProduct')
    const removeSubscriptionProduct = productIndex => $store.dispatch('subscription/removeSubscriptionProduct', productIndex)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const subscriptionMapField = mapFields('subscription', [
      'subscription.client_id',
      'subscription.startdate',
      'subscription.name',
      'subscription.cancelationperiod',
      'subscription.period',
      'subscription.tenant',
      'subscription.invoicerange',
    ])

    getEditSubscription().then(_ => {
      const regex = /([0-9]+).([a-z]+)/
      const periodMatches = subscriptionMapField.period.value.match(regex)
      const cancelationperiodMatches = subscriptionMapField.cancelationperiod.value.match(regex)

      period_amount.value = periodMatches[1]
      period_unit.value = periodMatches[2]
      cancelationperiod_amount.value = cancelationperiodMatches[1]
      cancelationperiod_unit.value = cancelationperiodMatches[2]
    })

    const client = computed(_ => {
      let client = subscription.value.client.billing_handle.organisation
        ? `(${subscription.value.client.billing_handle.organisation}) `
        : ''
      return (client += `${subscription.value.client.billing_handle.firstname} ${subscription.value.client.billing_handle.lastname}`)
    })

    watch(combinedPeriod, val => subscriptionMapField.period.value = val)
    watch(combinedCancelationperiod, val => subscriptionMapField.cancelationperiod.value = val)

    const submitForm = _ => {
      if (pendingSave.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editSubscription().then(({ subscription, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Abo erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'subscriptions-item',
            params: { id: subscription.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearSubscription()
    })

    return {
      prevRoute,
      client,
      pending,
      pendingSave,
      errors,
      period_amount,
      period_unit,
      cancelationperiod_amount,
      cancelationperiod_unit,
      submitForm,
      addSubscriptionProduct,
      removeSubscriptionProduct,
      ...subscriptionMapField,
      intervals,
    }
  },
  computed: {
    ...mapMultiRowFields('subscription', ['subscription.subscription_products']),
  },
})
</script>
