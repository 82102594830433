<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Subscriptions')" title-actions>
      <template #titleActions>
        <v-btn to="Subscriptions/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="subscriptions"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (['closed', 'storno', 'cancled'].includes(item.status) ? highlightClasses : '')"
      >
        <template #[`item.client`]="{ item }">
          <router-link v-if="item.client_id" :to="{ name: 'clients-item', params: { id: item.client_id } }">
            <b>{{ item.client.contact_handle.organisation }}</b>
            {{ item.client.contact_handle.firstname }}
            {{ item.client.contact_handle.lastname }}
          </router-link>
        </template>

        <!--
        <template #[`item.billingdate`]="{ item }">
          {{ item.billingdate | moment('add', '14 days', 'DD.MM.YYYY') }}
        </template>

        <template #[`item.status`]="{ item }">
          <InvoiceRowStateButtons
            :invoice="item"
            :pending-invoices="pendingSubscriptions"
            @onChangeState="changeState(item.id, ...arguments)"
          />
        </template>
        -->

        <template #[`item.actions`]="{ item }">
          <SubscriptionRowActions
            :subscription="item"
            :pending-subscriptions="pendingSubscriptions"
            @onRemove="remove(item.id)"
          />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import SubscriptionRowActions from '@/components/Subscriptions/SubscriptionRowActions'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewSubscriptions',
  components: {
    AppCard,
    AppPagination,
    SubscriptionRowActions,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const pendingSubscriptions = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Lastrenewed',
        value: 'lastrenewed',
      },
      {
        sortable: false,
        text: 'Nextrenew',
        value: 'nextrenew',
      },
      {
        sortable: false,
        text: 'Period',
        value: 'period',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const subscriptions = computed(_ => $store.state.subscriptions.subscriptions)
    const pagination = computed(_ => $store.state.subscriptions.pagination)
    const pending = computed(_ => $store.state.subscriptions.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getSubscriptions = page => $store.dispatch('subscriptions/getSubscriptions', page)
    const deleteSubscription = invoiceId => $store.dispatch('subscriptions/deleteSubscription', invoiceId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getSubscriptions(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getSubscriptions(page.value)
    })

    const remove = id => {
      const pendingButtonIndex = pendingSubscriptions.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      if (confirm('Sicher, dass das Product gelöscht werden soll?')) {
        pendingSubscriptions.push(id)

        deleteSubscription(id)
          .then(async _ => {

            addToast({
              msg: 'Abo erfolgreich gelöscht',
              type: 'error',
            })

            await getSubscriptions(page.value)
            pendingSubscriptions.splice(pendingButtonIndex, 1)
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
            pendingSubscriptions.splice(pendingButtonIndex, 1)
          })
      }
    }

    return {
      page,
      pendingSubscriptions,
      headers,
      subscriptions,
      pagination,
      pending,
      highlightClasses,
      remove,
    }
  },
})
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
