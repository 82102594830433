<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :loading="pending" :title="$t('Edit Invoice')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <VTextFieldWithValidation label="Client" disabled :value="client" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="billingdate"
                      label="Billingdate"
                      name="billingdate"
                      rules="required"
                      placeholder="Enter Billingdate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="2">
                    <VTextFieldWithValidation
                      v-model="paymenttime"
                      label="Paymenttime"
                      name="paymenttime"
                      rules="required|integer|min_value:0"
                      placeholder="Enter Paymenttime"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="skonto"
                      label="Skonto"
                      name="skonto"
                      rules="integer|min_value:0"
                      placeholder="Enter Skonto"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation label="Tenant" :value="invoicePresets.tenants[tenant_id]" disabled />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="invoicerange_id"
                      rules="required"
                      label="Invoicerange"
                      :items="invoiceRangesArray"
                      menu-props="offset-y"
                      placeholder="Select Invoicerange"
                    />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <template v-for="(invoice_product, key) in invoice_products">
                      <InvoiceProductRow
                        :key="key"
                        :index="key"
                        :length="invoice_products.length"
                        :invoice-product="invoice_product"
                        @removeInvoiceProduct="removeInvoiceProduct"
                      />
                    </template>

                    <v-btn color="success" @click="addInvoiceProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <VTextareaWithValidation
                      v-model="extratext"
                      label="Invoice Text"
                      name="invoicetext"
                      placeholder="Enter Invoice Text"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import InvoiceProductRow from '@/components/Invoices/InvoiceProductRow'
import ItemWrapper from '@/layouts/ItemWrapper'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'EditInvoice',
  components: {
    AppCard,
    DatePickerWithValidation,
    InvoiceProductRow,
    ItemWrapper,
    LogBox,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Invoices')

    // store
    const pending = computed(_ => $store.state.invoice.pending)
    const invoice = computed(_ => $store.state.invoice.invoice)
    const invoicePresets = computed(_ => $store.state.invoice.invoicePresets)
    const pendingSave = computed(_ => $store.state.invoice.pendingSave)
    const errors = computed(_ => $store.state.invoice.errors)
    const getEditInvoice = _ => $store.dispatch('invoice/getEditInvoice')
    const editInvoice = _ => $store.dispatch('invoice/editInvoice')
    const clearInvoice = _ => $store.dispatch('invoice/clearInvoice')
    const addInvoiceProduct = _ => $store.dispatch('invoice/addInvoiceProduct')
    const removeInvoiceProduct = productIndex => $store.dispatch('invoice/removeInvoiceProduct', productIndex)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditInvoice()

    const invoiceRangesArray = computed(_ => {
      return parseArray(invoicePresets.value.invoiceranges)
    })

    const client = computed(_ => {
      let client = invoice.value.client.billing_handle.organisation
        ? `(${invoice.value.client.billing_handle.organisation}) `
        : ''
      return (client += `${invoice.value.client.billing_handle.firstname} ${invoice.value.client.billing_handle.lastname}`)
    })

    const parseArray = arr => {
      const array = []
      if (arr) {
        for (const [
              value,
              text,
            ] of Object.entries(arr)) {
          array.push({
            value: parseInt(value),
            text,
          })
        }
      }
      return array
    }

    const submitForm = _ => {
      if (pendingSave.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editInvoice().then(({ invoice, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Rechnung erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'invoices-item',
            params: { id: invoice.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearInvoice()
    })

    return {
      prevRoute,
      client,
      invoiceRangesArray,
      invoicePresets,
      pending,
      pendingSave,
      errors,
      submitForm,
      addInvoiceProduct,
      removeInvoiceProduct,
      ...mapFields('invoice', [
        'invoice.client_id',
        'invoice.billingdate',
        'invoice.paymenttime',
        'invoice.skonto',
        'invoice.tenant_id',
        'invoice.invoicerange_id',
        'invoice.extratext',
      ]),
    }
  },
  computed: {
    ...mapMultiRowFields('invoice', ['invoice.invoice_products']),
  },
})
</script>
