<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :title="$t('Edit Voucher')" :prev-route="prevRoute" :loading="pending">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" md="6">
                    <!-- List all existing files -->
                    <AppDropzone
                      id="upload"
                      :bearer-token="token"
                      :config="config"
                      :duplicate-check="true"
                      upload-url="uploads/upload"
                      @onFilesChanged="updateVoucherFiles"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row wrap>
                      <v-col cols="12" sm="4">
                        <VSelectWithValidation
                          v-model="type"
                          rules="required"
                          label="Type"
                          :items="types"
                          menu-props="offset-y"
                          placeholder="Select Type"
                        />
                      </v-col>

                      <v-col cols="12" sm="4">
                        <VSelectWithValidation
                          v-model="direction"
                          rules="required"
                          label="Direction"
                          :items="directions"
                          menu-props="offset-y"
                          placeholder="Select Direction"
                        />
                      </v-col>

                      <v-col cols="12" sm="4">
                        <SearchSelectHandle v-model="handles_id" rules="required" :handle="voucher.handle" />
                      </v-col>

                      <v-col cols="12" sm="8">
                        <VTextFieldWithValidation
                          v-model="name"
                          label="Voucher Number"
                          name="name"
                          placeholder="Enter Voucher Number"
                        />
                      </v-col>

                      <v-col cols="12" sm="4">
                        <DatePickerWithValidation
                          v-model="voucher_date"
                          label="Voucher Date"
                          name="voucher date"
                          rules="required"
                          placeholder="Enter Voucher Date"
                        />
                      </v-col>

                      <v-col v-if="direction === 'in'" cols="12">
                        <VSelectWithValidation
                          v-model="payment_type"
                          rules="required"
                          label="Payment Type"
                          :items="payments"
                          menu-props="offset-y"
                          placeholder="Select Payment Type"
                        />
                      </v-col>

                      <v-col cols="12" :class="{ 'col-sm-6': payment_type !== 'open' && payment_type !== '' }">
                        <DatePickerWithValidation
                          v-model="voucher_duedate"
                          label="Voucher Due Date"
                          name="voucher due date"
                          rules="required"
                          placeholder="Enter Voucher Due Date"
                        />
                      </v-col>

                      <v-col v-if="payment_type !== 'open' && payment_type !== ''" cols="12" sm="6">
                        <DatePickerWithValidation
                          v-model="voucher_paydate"
                          label="Pay Date"
                          name="pay date"
                          rules="required"
                          placeholder="Enter Pay Date"
                        />
                      </v-col>

                      <v-col cols="12">
                        <VTextareaWithValidation
                          v-model="description"
                          label="Description"
                          name="description"
                          placeholder="Enter Description"
                        />
                      </v-col>

                      <v-col cols="12">
                        <h3 v-show="direction === 'in'" class="text-h5 my-0">Ausgaben</h3>
                        <h3 v-show="direction === 'out'" class="text-h5 my-0">Einnahmen</h3>
                      </v-col>

                      <v-col v-show="direction === 'out' || direction === 'in'" cols="12">
                        <template v-for="(voucher_item, key) in voucher_items">
                          <VoucherItemRow
                            :key="key"
                            row-classes="small-pad"
                            :index="key"
                            :voucher-item="voucher_item"
                            @onRemoveItem="removeVoucherItem"
                          />
                        </template>

                        <v-btn color="success" @click="addVoucherItem">Partial Amount</v-btn>
                      </v-col>

                      <!-- Total -->
                      <v-col cols="12" sm="3">
                        <VTextFieldWithValidation label="Steuer 7%" name="tax 7%" disabled placeholder="0.00€" />
                      </v-col>

                      <v-col cols="12" sm="3">
                        <VTextFieldWithValidation label="Steuer 19%" name="tax 19%" disabled placeholder="0.00€" />
                      </v-col>

                      <v-col cols="12" sm="3">
                        <VTextFieldWithValidation
                          v-model="amount"
                          label="Amount"
                          name="amount"
                          step="0.01"
                          rules="required|min_value:1"
                          placeholder="Enter Currency"
                          min="0"
                        />
                      </v-col>

                      <v-col cols="12" sm="3">
                        <VSelectWithValidation
                          v-model="currency"
                          rules="required"
                          label="Currency"
                          :items="currencies"
                          menu-props="offset-y"
                          placeholder="Select Currency"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
// import { isEmpty, clone } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

import VoucherItemRow from '@/components/Vouchers/VoucherItemRow.vue'
import AppDropzone from '@/components/UI/AppDropzone'
import AppCard from '@/components/UI/AppCard'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import ItemWrapper from '@/layouts/ItemWrapper'
import LogBox from '@/components/Test/LogBox.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import SearchSelectHandle from '@/components/Handles/SearchSelectHandle.vue'

export default {
  name: 'EditVoucher',
  components: {
    VoucherItemRow,
    AppDropzone,
    AppCard,
    DatePickerWithValidation,
    ItemWrapper,
    LogBox,
    SearchSelectHandle,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  data() {
    return {
      delayTimer: null,
      config: {
        maxFiles: 3,
        url: process.env.VUE_APP_API_URL,
        addRemoveLinks: true,
        maxFilesize: 20,
      },
      types: [
        'invoice', // Invoice
        'quote', // Quote
        'orderconfirmation', // Order Confirmation
      ],
      directions: [
        // Einnahme
        'out', // Incoming
        // Ausgabe
        'in', // Outgoing
      ],
      payments: [
        'open', // Noch offen
        'debit', // Wird abgebucht
        'cash', // Aus Kasse
        'privat', // Privat gezahlt
      ],
      currencies: [
        'EUR',
        'USD',
        'GBP',
      ],
      prevRoute: '/Vouchers',
    }
  },
  computed: {
    ...mapFields('voucher', [
      'voucher.amount',
      'voucher.currency',
      'voucher.description',
      'voucher.direction',
      'voucher.files',
      'voucher.handles_id',
      'voucher.name',
      'voucher.payment_type',
      'voucher.type',
      'voucher.voucher_date',
      'voucher.voucher_duedate',
      'voucher.voucher_paydate',
    ]),
    ...mapMultiRowFields('voucher', ['voucher.voucher_items']),
    ...mapState('voucher', [
      'voucher',
      'pending',
      'pendingSave',
      'errors',
    ]),
    ...mapState('auth', ['token']),
    // placeholderHandle() {
    //   let placeholder = 'Search Handle'
    //   if (this.handles_id !== null) {
    //     placeholder = `${this.voucher.handle.unique_name} - ${this.voucher.handle.firstname} ${this.voucher.handle.lastname}`
    //   }
    //   return placeholder
    // },
    // voucher_date_format: {
    //   get: function() {
    //     if (isEmpty(this.voucher_date)) {
    //       return ''
    //     }
    //     return this.$moment(this.voucher_date).format('YYYY-MM-DD')
    //   },
    //   // setter
    //   set: function(newValue) {
    //     if (!isEmpty(newValue)) {
    //       this.voucher_date = this.$moment(newValue).format('YYYY-MM-DD HH:mm')
    //     } else {
    //       this.voucher_date = ''
    //     }
    //   },
    // },
    // voucher_duedate_format: {
    //   get: function() {
    //     if (isEmpty(this.voucher_duedate)) {
    //       return ''
    //     }
    //     return this.$moment(this.voucher_duedate).format('YYYY-MM-DD')
    //   },
    //   // setter
    //   set: function(newValue) {
    //     if (!isEmpty(newValue)) {
    //       this.voucher_duedate = this.$moment(newValue).format('YYYY-MM-DD HH:mm')
    //     } else {
    //       this.voucher_duedate = ''
    //     }
    //   },
    // },
  },
  watch: {
    payment_type(val, oldVal) {
      if (val === 'open') {
        this.voucher_paydate = ''
        return
      }
      if (oldVal === 'open') {
        this.voucher_duedate = ''
      }
    },
    direction(val, oldVal) {
      if (oldVal) {
        this.voucher_paydate = ''
        this.voucher_duedate = ''
        this.payment_type = ''
      }
    },
  },
  created() {
    this.getEditVoucher()
  },
  destroyed() {
    this.clearVoucher()
  },
  methods: {
    ...mapActions('voucher', [
      'addVoucherItem',
      'clearVoucher',
      'editVoucher',
      'getEditVoucher',
      'removeVoucherItem',
      'updateVoucherFiles',
    ]),
    submitForm() {
      if (this.pendingSave) {
        return
      }

      this.editVoucher().then(({ errors }) => {
        if (!errors) this.$router.push({ path: '/Vouchers' })
      })
    },
  },
}
</script>
