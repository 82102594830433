import http from './http'

export default {
  setStatus(quote) {
    return http.get(`quotes/status/${quote.id}/${quote.status}`)
  },
  getQuotePresets() {
    return http.get('quotes/add')
  },
  getPdf(quoteId) {
    return http.get(`quotes/viewPdf/${quoteId}`)
  },
}
