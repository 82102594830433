<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
    <v-switch v-model="innerValue" slot-scope="{ errors }" :error-messages="errors" v-bind="$attrs" v-on="$listeners" />
  </ValidationProvider>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'VSwitchWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { emit }) {
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(_ => props.value, newVal => innerValue.value = newVal)

    return {
      innerValue,
    }
  },
})
</script>
