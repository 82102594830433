var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OverviewWrapper',[_c('AppCard',{attrs:{"title":_vm.$t('All Quotes'),"title-actions":""},scopedSlots:_vm._u([{key:"titleActions",fn:function(){return [_c('v-btn',{attrs:{"to":"Quotes/create","color":"secondary","fab":"","small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}])},[(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination,"location":"top"}}):_vm._e(),_c('v-data-table',{staticClass:"v-data-table__overview",attrs:{"headers":_vm.headers,"items":_vm.quotes,"page":_vm.pagination.page,"items-per-page":_vm.pagination.perPage,"loading":_vm.pending,"hide-default-footer":"","item-class":function (item) { return (item.status === 'expired' ? _vm.highlightClasses : ''); }},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client_id)?_c('router-link',{attrs:{"to":{ name: 'clients-item', params: { id: item.client_id } }}},[_c('b',[_vm._v(_vm._s(item.client.contact_handle.organisation))]),_vm._v(" "+_vm._s(item.client.contact_handle.firstname)+" "+_vm._s(item.client.contact_handle.lastname)+" ")]):_vm._e()]}},{key:"item.quotedate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.quotedate,'DD.MM.YYYY'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('QuoteRowStateButtons',{attrs:{"quote":item,"pending-quotes":_vm.pendingQuotes,"disabled":_vm.pending},on:{"onChangeState":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.changeState.apply(void 0, [ item.id ].concat( argsArray ))}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('QuoteRowActions',{attrs:{"quote":item,"pending-quotes":_vm.pendingQuotes},on:{"onRemove":function($event){return _vm.remove(item.id)}}})]}}],null,true)}),(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }