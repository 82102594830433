<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Quotes')" title-actions>
      <template #titleActions>
        <v-btn to="Quotes/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="quotes"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (item.status === 'expired' ? highlightClasses : '')"
      >
        <template #[`item.client`]="{ item }">
          <router-link v-if="item.client_id" :to="{ name: 'clients-item', params: { id: item.client_id } }">
            <b>{{ item.client.contact_handle.organisation }}</b>
            {{ item.client.contact_handle.firstname }}
            {{ item.client.contact_handle.lastname }}
          </router-link>
        </template>

        <template #[`item.quotedate`]="{ item }">
          {{ item.quotedate | moment('DD.MM.YYYY') }}
        </template>

        <!-- <template v-slot:item.amount="{ item }">
          {{ item.amount }} €
        </template> -->

        <template #[`item.status`]="{ item }">
          <QuoteRowStateButtons
            :quote="item"
            :pending-quotes="pendingQuotes"
            :disabled="pending"
            @onChangeState="changeState(item.id, ...arguments)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <QuoteRowActions :quote="item" :pending-quotes="pendingQuotes" @onRemove="remove(item.id)" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, computed, watch, ref, reactive } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import QuoteRowActions from '@/components/Quotes/QuoteRowActions.vue'
import QuoteRowStateButtons from '@/components/Quotes/QuoteRowStateButtons.vue'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewQuotes',
  components: {
    AppCard,
    AppPagination,
    QuoteRowActions,
    QuoteRowStateButtons,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const pendingQuotes = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Quote Nr.',
        value: 'quote_nr',
      },
      {
        sortable: false,
        text: 'Quotedate',
        value: 'quotedate',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const quotes = computed(_ => $store.state.quotes.quotes)
    const pagination = computed(_ => $store.state.quotes.pagination)
    const pending = computed(_ => $store.state.quotes.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getQuotes = page => $store.dispatch('quotes/getQuotes', page)
    const setStatus = status => $store.dispatch('quotes/setStatus', status)
    const deleteQuote = quoteId => $store.dispatch('quotes/deleteQuote', quoteId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getQuotes(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getQuotes(page.value)
    })


    const changeState = (id, status) => {
      const quote = {
        id,
        status,
      }

      const pendingButtonIndex = pendingQuotes.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingQuotes.push(id)

      setStatus(quote)
        .then(response => {
          pendingQuotes.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Status erfolgreich geändert',
            type: 'info',
          })

          getQuotes(page)
        })
        .catch(error => {
          addToast({
            msg: 'Statusänderung fehlgeschlagen',
            type: 'error',
          })
          pendingQuotes.splice(pendingButtonIndex, 1)
        })
    }

    const remove = id => {
      const pendingButtonIndex = pendingQuotes.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingQuotes.push(id)

      deleteQuote(id)
        .then(response => {
          pendingQuotes.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Angebot erfolgreich gelöscht',
            type: 'error',
          })

          getQuotes(page.value)
        })
        .catch(error => {
          addToast({
            msg: 'Löschung fehlgeschlagen',
            type: 'error',
          })
          pendingQuotes.splice(pendingButtonIndex, 1)
        })
    }

    return {
      headers,
      quotes,
      pagination,
      pendingQuotes,
      pending,
      highlightClasses,
      remove,
      changeState,
    }
  },
})
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
