<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :title="$t('Create Quote')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <SearchSelectClient v-model="client_id" rules="required" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="quotedate"
                      label="Quotedate"
                      name="quotedate"
                      rules="required"
                      placeholder="Enter Quotedate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="2">
                    <VTextFieldWithValidation
                      v-model="quotetime"
                      label="Quotetime"
                      name="quotetime"
                      rules="required|integer|min_value:0"
                      placeholder="Enter Quotetime"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="tenant_id"
                      rules="required"
                      label="Tenant"
                      :items="tenantsArray"
                      :disabled="pendingPresets"
                      menu-props="offset-y"
                      placeholder="Select Tenant"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="quoterange_id"
                      rules="required"
                      label="Quoterange"
                      :items="quoteRangesArray"
                      :disabled="pendingPresets"
                      menu-props="offset-y"
                      placeholder="Select Quoterange"
                    />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <template v-for="(quote_product, key) in quote_products">
                      <QuoteProductRow
                        :key="key"
                        :index="key"
                        :quote-product="quote_product"
                        :length="quote_products.length"
                        @removeQuoteProduct="removeQuoteProduct"
                      />
                    </template>

                    <v-btn color="success" @click="addQuoteProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <VTextareaWithValidation
                      v-model="extratext"
                      label="Quote Text"
                      name="quotetext"
                      placeholder="Enter Quote Text"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import QuoteProductRow from '@/components/Quotes/QuoteProductRow.vue'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateQuote',
  components: {
    AppCard,
    DatePickerWithValidation,
    QuoteProductRow,
    ItemWrapper,
    LogBox,
    SearchSelectClient,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Quotes')

    // store
    const pending = computed(_ => $store.state.quote.pending)
    const pendingPresets = computed(_ => $store.state.quote.pendingPresets)
    const quotePresets = computed(_ => $store.state.quote.quotePresets)
    const errors = computed(_ => $store.state.quote.errors)
    const getQuotePresets = _ => $store.dispatch('quote/getQuotePresets')
    const createQuote = _ => $store.dispatch('quote/createQuote')
    const clearQuote = _ => $store.dispatch('quote/clearQuote')
    const addQuoteProduct = _ => $store.dispatch('quote/addQuoteProduct')
    const removeQuoteProduct = productIndex => $store.dispatch('quote/removeQuoteProduct', productIndex)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const quoteMapField = mapFields('quote', [
      'quote.client_id',
      'quote.quotedate',
      'quote.quotetime',
      'quote.tenant_id',
      'quote.quoterange_id',
      'quote.extratext',
    ])

    const quoteRangesArray = computed(_ => parseArray(quotePresets.value.quoteranges))
    const tenantsArray = computed(_ => parseArray(quotePresets.value.tenants))

    getQuotePresets()
    quoteMapField.quotedate.value = $moment().format('YYYY-MM-DD')

    watch(quotePresets, _ => {
      quoteMapField.quoterange_id.value = parseInt(Object.keys(quotePresets.value.quoteranges)[0])
      quoteMapField.tenant_id.value = parseInt(Object.keys(quotePresets.value.tenants)[0])
    })

    const parseArray = arr => {
      const array = []
      if (arr) {
        for (const [
              value,
              text,
            ] of Object.entries(arr)) {
          array.push({
            value: parseInt(value),
            text,
          })
        }
      }
      return array
    }

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createQuote().then(({ quote, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Angebot erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'quotes-item',
            params: { id: quote.id },
          })
        } else {
          $router.push({ path: '/Quotes' })
        }
      })
    }

    onUnmounted(_ => {
      clearQuote()
    })

    return {
      prevRoute,
      quoteRangesArray,
      tenantsArray,
      pending,
      pendingPresets,
      errors,
      ...quoteMapField,
      addQuoteProduct,
      removeQuoteProduct,
      submitForm,
    }
  },
  computed: {
    ...mapMultiRowFields('quote', ['quote.quote_products']),
  },
})
</script>
