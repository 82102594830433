import jwtDecode from 'jwt-decode'
import router from '@/router'
import { isNull } from 'lodash'

// client side check if the token in the localStorage is expired.
const isTokenExpired = token => {
  // check if token exists
  if (token) {
    const decodeToken = jwtDecode(token)
    // check if token is expired
    if (decodeToken.exp > Date.now() / 1000) {
      return false
    }
  }
  return true
}

const getMenuLinks = obj => {
  let list = []
  const key = 'link'
  const children = 'items'

  if (!obj) return list

  if (obj instanceof Array) {
    for (const i in obj) {
      list = list.concat(getMenuLinks(obj[i], key, []))
    }
    return list
  }

  if (obj[key]) {
    list.push(obj[key])
  }

  if (typeof obj === 'object' && obj[children] !== null) {
    list = list.concat(getMenuLinks(obj[children]))
  }

  return list
}

const state = {
  token: null,
  session: null,
  pending: false,
  errors: {},
}

const getters = {
  isAuthenticated: state => !!state.token && !isTokenExpired(state.token),
  menus: state => state.session && state.session.menu,
  routes: state => {
    let routes = []
    if (state.session && state.session.menu) {
      routes = getMenuLinks(state.session.menu)
    }
    return routes
  },
  user: state => state.session && state.session.user,
}

const actions = {
  login({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)
      // call api auth/login with data
      this.api.auth
        .login(credentials)
        .then(({ data }) => {
          // TODO: comment out for dev
          // if (isNull(data) || !data.success) {
          if (isNull(data)) {
            commit('LOGIN_FAIL', { msg: 'Login failed' })
            return
          }
          // call LOGIN_SUCCESS from mutations
          commit('LOGIN_SUCCESS', data)
          // action getSession
          dispatch('getSession').catch(err => err)
          resolve()
        })
        .catch(error => {
          commit('LOGIN_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getSession({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.auth
        .getSession()
        .then(({ data }) => {
          commit('GET_SESSION_SUCCESS', data)
          dispatch('setRoutes')
          resolve()
        })
        .catch(error => {
          commit('GET_SESSION_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  initAuth({ dispatch, commit }) {
    return new Promise(resolve => {
      // get token and session from localStorage
      const token = localStorage.getItem('token')
      const session = JSON.parse(localStorage.getItem('session'))

      if (token && session) {
        commit('INIT_AUTH', { token, session })
        dispatch('setRoutes')
        resolve()
      } else {
        dispatch('logout').catch(err => err)
      }
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.auth
        .logout()
        .then(() => {
          commit('LOGOUT_SUCCESS')
          resolve()
        })
        .catch(error => {
          commit('LOGOUT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  setRoutes({ getters }) {
    const routeLinks = getters.routes
    const routes = []

    routeLinks.forEach(routeLink => {
      const routeName = routeLink.replace('/', '')

      const route = {
        path: routeLink,
        meta: {
          title: routeName,
          type: routeName.toLowerCase(),
          requiresAuth: true,
        },
        props: true,
        name: routeName.toLowerCase(),
      }

      const listRoute = Object.assign({}, route)
      try {
        listRoute.component = () => import(`@/views/${routeName}/${routeName}Overview`)
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') console.log(`Not found view: ${routeName}`)
        listRoute.component = () => import('@/views/NotFoundComponent')
      }

      const singleRoute = Object.assign({}, route)
      try {
        singleRoute.component = () => import(`@/views/${routeName}/${routeName}Item`)
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') console.log(`Not found view: ${routeName} Item`)
        singleRoute.component = () => import('@/views/NotFoundComponent')
      }
      singleRoute.path += '/:id/:tab?'
      singleRoute.name += '-item'

      const singleCreateRoute = Object.assign({}, route)
      try {
        singleCreateRoute.component = () => import(`@/views/${routeName}/${routeName}ItemCreate`)
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') console.log(`Not found view: ${routeName} ItemCreate`)
        singleCreateRoute.component = () => import('@/views/NotFoundComponent')
      }
      singleCreateRoute.path += '/create'
      singleCreateRoute.name += '-create'

      const singleEditRoute = Object.assign({}, route)
      try {
        singleEditRoute.component = () => import(`@/views/${routeName}/${routeName}ItemEdit`)
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') console.log(`Not found view: ${routeName} ItemEdit`)
        singleEditRoute.component = () => import('@/views/NotFoundComponent')
      }
      singleEditRoute.path += '/edit/:id'
      singleEditRoute.name += '-edit'

      routes.push(listRoute, singleCreateRoute, singleEditRoute, singleRoute)
    })

    router.addRoutes(routes)
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  INIT_AUTH(state, { token, session }) {
    state.errors = {}
    state.session = session
    state.token = token
  },
  LOGIN_SUCCESS(state, { token }) {
    state.errors = {}
    state.token = token
    localStorage.setItem('token', token)
    state.error = null
  },
  LOGIN_FAIL(state, errors) {
    state.errors = errors
  },
  GET_SESSION_SUCCESS(state, session) {
    state.errors = {}
    localStorage.setItem('session', JSON.stringify(session))
    state.session = session
  },
  GET_SESSION_FAIL(state, errors) {
    state.errors = errors
    state.token = null
    state.session = null
    localStorage.removeItem('token')
    localStorage.removeItem('session')
  },
  LOGOUT_SUCCESS(state) {
    state.errors = {}
    state.token = null
    state.session = null
    localStorage.removeItem('token')
    localStorage.removeItem('session')
  },
  LOGOUT_FAIL(state, errors) {
    state.errors = errors
    state.token = null
    state.session = null
    localStorage.removeItem('token')
    localStorage.removeItem('session')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
