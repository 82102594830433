<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :title="$t('Create Subscription')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-row wrap>
                <v-col cols="12" sm="6" lg="4">
                  <SearchSelectClient v-model="client_id" rules="required" />
                </v-col>

                <v-col cols="12" sm="3" lg="4">
                  <DatePickerWithValidation
                    v-model="startdate"
                    label="Startdate"
                    name="startdate"
                    rules="required"
                    placeholder="Enter Startdate"
                  />
                </v-col>

                <v-col cols="12" sm="3" lg="4">
                  <VTextFieldWithValidation
                    v-model="name"
                    label="Name"
                    name="name"
                    rules="required"
                    placeholder="Enter Name"
                  />
                </v-col>

                <v-col cols="12" sm="6" lg="4">
                  <v-row>
                    <v-col cols="6" sm="4">
                      <VTextFieldWithValidation
                        v-model="period_amount"
                        label="Period"
                        name="period"
                        type="number"
                        min="1"
                        rules="required"
                        placeholder="Enter Period"
                      />
                    </v-col>

                    <v-col cols="6" sm="8">
                      <VSelectWithValidation
                        v-model="period_unit"
                        required
                        label="Period Unit"
                        :items="intervals"
                        menu-props="offset-y"
                        placeholder="Select Interval"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" lg="4">
                  <v-row>
                    <v-col cols="6" sm="4">
                      <VTextFieldWithValidation
                        v-model="cancelationperiod_amount"
                        label="Cancelationperiod"
                        name="period"
                        type="number"
                        min="1"
                        rules="required"
                        placeholder="Enter Period"
                      />
                    </v-col>

                    <v-col cols="6" sm="8">
                      <VSelectWithValidation
                        v-model="cancelationperiod_unit"
                        required
                        label="Period Unit"
                        :items="intervals"
                        menu-props="offset-y"
                        placeholder="Select Interval"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="4">
                  <VSelectWithValidation
                    v-model="tenant_id"
                    rules="required"
                    label="Tenant"
                    :items="tenantsArray"
                    :disabled="pendingPresets"
                    menu-props="offset-y"
                    placeholder="Select Tenant"
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <VSelectWithValidation
                    v-model="invoicerange_id"
                    rules="required"
                    label="Invoicerange"
                    :items="invoiceRangesArray"
                    :disabled="pendingPresets"
                    menu-props="offset-y"
                    placeholder="Select Invoicerange"
                  />
                </v-col>

                <v-col cols="12" px-0>
                  <v-divider />
                </v-col>

                <v-col cols="12">
                  <h3 class="text-h5 mt-0">Positions</h3>
                  <template v-for="(subscription_product, key) in subscription_products">
                    <SubscriptionProductRow
                      :key="key"
                      :index="key"
                      :subscription-product="subscription_product"
                      :length="subscription_products.length"
                      @removeSubscriptionProduct="removeSubscriptionProduct"
                    />
                  </template>

                  <v-btn color="success" @click="addSubscriptionProduct">Add position</v-btn>
                </v-col>

                <v-col cols="12" px-0>
                  <v-divider />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import { intervals } from '@/config'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import SubscriptionProductRow from '@/components/Subscriptions/SubscriptionProductRow'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateSubscription',
  components: {
    AppCard,
    DatePickerWithValidation,
    SubscriptionProductRow,
    ItemWrapper,
    LogBox,
    SearchSelectClient,
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Subscriptions')

    const period_amount = ref(1)
    const period_unit = ref('months')
    const cancelationperiod_amount = ref(1)
    const cancelationperiod_unit = ref('months')

    const combinedPeriod = computed(_ => {
      return `${period_amount.value} ${period_unit.value}`
    })
    const combinedCancelationperiod = computed(_ => {
      return `${cancelationperiod_amount.value} ${cancelationperiod_unit.value}`
    })

    // store
    const pending = computed(_ => $store.state.subscription.pending)
    const pendingPresets = computed(_ => $store.state.subscription.pendingPresets)
    const subscriptionPresets = computed(_ => $store.state.subscription.subscriptionPresets)
    const errors = computed(_ => $store.state.subscription.errors)
    const getSubscriptionPresets = _ => $store.dispatch('subscription/getSubscriptionPresets')
    const createSubscription = _ => $store.dispatch('subscription/createSubscription')
    const clearSubscription = _ => $store.dispatch('subscription/clearSubscription')
    const addSubscriptionProduct = _ => $store.dispatch('subscription/addSubscriptionProduct')
    const removeSubscriptionProduct = productIndex => $store.dispatch('subscription/removeSubscriptionProduct', productIndex)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const subscriptionMapField = mapFields('subscription', [
      'subscription.client_id',
      'subscription.startdate',
      'subscription.name',
      'subscription.cancelationperiod',
      'subscription.period',
      'subscription.tenant_id',
      'subscription.invoicerange_id',
    ])

    const invoiceRangesArray = computed(_ => parseArray(subscriptionPresets.value.invoiceranges))
    const tenantsArray = computed(_ => parseArray(subscriptionPresets.value.tenants))

    getSubscriptionPresets()
    subscriptionMapField.startdate.value = $moment().format('YYYY-MM-DD')
    subscriptionMapField.period.value = `${period_amount.value} ${period_unit.value}`
    subscriptionMapField.cancelationperiod.value = `${cancelationperiod_amount.value} ${cancelationperiod_unit.value}`

    watch(subscriptionPresets, _ => {
      subscriptionMapField.invoicerange_id.value = parseInt(Object.keys(subscriptionPresets.value.invoiceranges)[0])
      subscriptionMapField.tenant_id.value = parseInt(Object.keys(subscriptionPresets.value.tenants)[0])
    })

    watch(combinedPeriod, val => subscriptionMapField.period.value = val)
    watch(combinedCancelationperiod, val => subscriptionMapField.cancelationperiod.value = val)

    const parseArray = arr => {
      const array = []
      if (arr) {
        for (const [
              value,
              text,
            ] of Object.entries(arr)) {
          array.push({
            value: parseInt(value),
            text,
          })
        }
      }
      return array
    }

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createSubscription().then(({ subscription, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Abo erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'subscriptions-item',
            params: { id: subscription.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearSubscription()
    })

    return {
      prevRoute,
      pending,
      pendingPresets,
      errors,
      period_amount,
      period_unit,
      cancelationperiod_amount,
      cancelationperiod_unit,
      ...subscriptionMapField,
      addSubscriptionProduct,
      removeSubscriptionProduct,
      submitForm,
      invoiceRangesArray,
      tenantsArray,
      intervals,
    }
  },
  computed: {
    ...mapMultiRowFields('subscription', ['subscription.subscription_products']),
  },
})
</script>
