const state = {
  quotes: [],
  pagination: {},
  pending: false,
  errors: {},
}

const getters = {}

const actions = {
  getQuotes({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_QUOTES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTES_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  setStatus({ commit }, quote) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.quote
        .setStatus(quote)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('UPDATE_STATUS_FAIL', data.errors)
            resolve()
          }
          commit('UPDATE_STATUS_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('UPDATE_STATUS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  deleteQuote({ commit, rootState }, quoteId) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, quoteId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('DELETE_QUOTE_FAIL', data.errors)
            resolve()
          }
          commit('DELETE_QUOTE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('DELETE_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  UPDATE_STATUS_SUCCESS() {
    return true
  },
  UPDATE_STATUS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_QUOTES_SUCCESS(state, { pagination, quotes }) {
    state.errors = {}
    state.quotes = quotes
    state.pagination = pagination
  },
  GET_QUOTES_FAIL(state, errors) {
    state.errors = errors
  },
  DELETE_QUOTE_SUCCESS(state) {
    state.errors = {}
  },
  DELETE_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
