<template>
  <AppCard title="File Uploader">
    <v-container grid-list-xl pt-3>
      <v-form @submit.prevent="onMultipleRequestSubmit">
        <v-row class="pad" wrap>
          <v-col cols="12">
            <v-text-field v-model="uploadurl" hide-details="auto" outlined label="Url" placeholder="Path" required />
          </v-col>

          <v-col cols="12">
            <AppDropzone
              id="upload"
              :bearer-token="authToken"
              :config="config"
              :duplicate-check="true"
              :upload-url="uploadurl"
              @onFilesChanged="updateFiles"
            />
          </v-col>

          <v-col v-if="files.length > 0" cols="12">
            <pre>{{ files }}</pre>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </AppCard>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppDropzone from '@/components/UI/AppDropzone'

export default defineComponent({
  name: 'Uploader',
  components: {
    AppCard,
    AppDropzone,
  },
  setup(props, { root: { $store } }) {
    const files = ref([])
    const config = {
      maxFiles: 3,
      url: process.env.VUE_APP_API_URL,
      addRemoveLinks: true,
      maxFilesize: 20,
    }
    const uploadurl = ref('')

    const authToken = computed(_ => $store.state.auth.token)

    const updateFiles = files => {
      files.value = files
    }

    return {
      uploadurl,
      config,
      authToken,
      files,
      updateFiles,
    }
  },
})
</script>


<style lang="scss" scoped>
::v-deep .vue-dropzone.dropzone {
  text-align: center;
  > .dz-preview.dz-error {
    .dz-error-message {
      top: 195px;
    }
  }
}
</style>
