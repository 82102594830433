<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Zones')" title-actions>
      <template #titleActions>
        <v-btn to="Zones/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="zones"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (!item.active ? disableClasses : '')"
      >
        <template #[`item.client_id`]="{ item }">
          <router-link v-if="item.clients_id" :to="{ name: 'clients-item', params: { id: item.clients_id } }">
            {{ item.clients_id }}
          </router-link>
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="zones" :item-id="item.id" info remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewZones',
  components: {
    AppCard,
    AppPagination,
    OverviewActions,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Client',
        value: 'client_id',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)
    const disableClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })

    // store
    const pending = computed(_ => $store.state.zones.pending)
    const zones = computed(_ => $store.state.zones.zones)
    const pagination = computed(_ => $store.state.zones.pagination)
    const getZones = page => $store.dispatch('zones/getZones', page)
    const removeZone = zoneId => $store.dispatch('zones/removeZone', zoneId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getZones(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getZones(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass die Zone gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeZone(id).then(_ => {
          addToast({
            msg: 'Zone erfolgreich gelöscht',
            type: 'error',
          })

          getZones(page.value)
        })
        .catch(error => {
          addToast({
            msg: 'Löschung fehlgeschlagen',
            type: 'error',
          })
        })
      }
    }

    return {
      headers,
      disableClasses,
      pending,
      zones,
      pagination,
      remove,
    }
  },
})
</script>
