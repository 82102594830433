<template>
  <ItemWrapper>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" slim>
      <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
        <v-row>
          <v-col cols="12" lg="6">
            <AppCard :title="$t('Create Client')" :prev-route="prevRoute">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="customer"
                      label="Customer ID"
                      name="customer_id"
                      placeholder="Enter Customer ID"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SelectGender v-model="sex" rules="required" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="organisation"
                      label="Organisation"
                      name="organisation"
                      placeholder="Enter Organisation"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="firstname"
                      rules="required"
                      label="Firstname"
                      name="firstname"
                      placeholder="Enter Firstname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="lastname"
                      rules="required"
                      label="Lastname"
                      name="lastname"
                      placeholder="Enter Lastname"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="email"
                      rules="required|email"
                      label="E-Mail Address"
                      name="email"
                      placeholder="Enter E-Mail Address"
                    />
                  </v-col>

                  <v-col cols="12" sm="8">
                    <VTextFieldWithValidation
                      v-model="street"
                      rules="required"
                      label="Street"
                      name="street"
                      placeholder="Enter Street"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="number"
                      rules="required"
                      label="Number"
                      name="number"
                      placeholder="Enter Number"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="zip"
                      rules="required"
                      label="Zip"
                      name="zip"
                      placeholder="Enter Zip"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="city"
                      rules="required"
                      label="City"
                      name="city"
                      placeholder="Enter City"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="country"
                      rules="required"
                      label="Country"
                      name="country"
                      placeholder="Enter Country"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="phone"
                      rules="required"
                      label="Phone"
                      name="phone"
                      placeholder="Enter Phone"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="fax" label="Fax" name="fax" placeholder="Enter Fax" />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </AppCard>
          </v-col>

          <v-col cols="12" lg="6">
            <AppCard :title="$t('Billing Options')">
              <v-container py-0>
                <v-row wrap>
                  <v-col v-if="options && options.pricelists" cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="pricelist_id"
                      label="Pricelist"
                      :items="options.pricelists"
                      :disabled="pendingOptions"
                      menu-props="offset-y"
                      placeholder="Select Pricelist"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="vatid" label="VAT ID" name="vatid" placeholder="Enter VAT ID" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSwitchWithValidation
                      v-model="reversecharge"
                      label="Reverse Charge"
                      :false-value="0"
                      :true-value="1"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="vatoverride"
                      clearable
                      label="VAT override"
                      :items="taxes"
                      menu-props="offset-y"
                      placeholder="Select Tax"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="paymenttypes"
                      label="Payment Type"
                      clearable
                      multiple
                      :items="paymentTypesArray"
                      menu-props="offset-y"
                      placeholder="Select Payment Type"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </AppCard>
          </v-col>

          <v-col cols="12" lg="6">
            <log-box :log="errors" />
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { taxes } from '@/config'
// TODO: receive from api?
const paymentTypesArray = [
  'paypal',
  'invoice',
  'transfer',
]
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SelectGender from '@/components/UI/SelectGender'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VSwitchWithValidation from '@/components/inputs/VSwitchWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateClient',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    SelectGender,
    VSelectWithValidation,
    VSwitchWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Clients')

    // store
    const pending = computed(_ => $store.state.client.pending)
    const pendingOptions = computed(_ => $store.state.client.pendingOptions)
    const options = computed(_ => $store.state.client.options)
    const errors = computed(_ => $store.state.client.errors)
    const createClient = _ => $store.dispatch('client/createClient')
    const getOptions = _ => $store.dispatch('client/getOptions')
    const clearClient = _ => $store.dispatch('client/clearClient')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getOptions()

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createClient().then(({ client, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Kunde erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'clients-item',
            params: { id: client.id },
          })
        } else {
          $router.push({ path: '/Clients' })
        }
      })
    }

    onUnmounted(_ => {
      clearClient()
    })

    return {
      prevRoute,
      pending,
      pendingOptions,
      options,
      errors,
      taxes,
      paymentTypesArray,
      submitForm,
      ...mapFields('client', [
        'client.contact_handle.city',
        'client.contact_handle.country',
        'client.contact_handle.email',
        'client.contact_handle.fax',
        'client.contact_handle.firstname',
        'client.contact_handle.lastname',
        'client.contact_handle.number',
        'client.contact_handle.organisation',
        'client.contact_handle.phone',
        'client.contact_handle.sex',
        'client.contact_handle.street',
        'client.contact_handle.zip',
        'client.customer',
        'client.paymenttypes',
        'client.pricelist_id',
        'client.reversecharge',
        'client.vatoverride',
        'client.vatid',
      ]),
    }
  },
})
</script>
