<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Users')" title-actions>
      <template #titleActions>
        <v-btn to="Users/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="users"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (!item.active ? 'grey lighten-4' : '')"
      >
        <template #[`item.client_id`]="{ item }">
          <router-link :to="{ name: 'clients-item', params: { id: item.client_id } }">
            {{ item.client_id }}
          </router-link>
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="users" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import OverviewWrapper from '@/layouts/OverviewWrapper'
import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'ViewUsers',
  components: {
    OverviewWrapper,
    AppCard,
    AppPagination,
    OverviewActions,
  },
  setup(props, { root: { $store, $route }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: 'Username',
        value: 'username',
      },
      {
        sortable: false,
        text: 'E-Mail',
        value: 'email',
      },
      {
        sortable: false,
        text: 'Rolle',
        value: 'role',
      },
      {
        sortable: false,
        text: 'Client',
        value: 'client_id',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.users.pending)
    const users = computed(_ => $store.state.users.users)
    const pagination = computed(_ => $store.state.users.pagination)
    const getUsers = page => $store.dispatch('users/getUsers', page)
    const removeUser = userId => $store.dispatch('users/removeUser', userId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getUsers(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getUsers(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass der Nutzer gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeUser(id)
          .then(_ => {
            addToast({
              msg: 'Nutzer erfolgreich gelöscht',
              type: 'error',
            })

            getUsers(page.value)
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      users,
      headers,
      pagination,
      remove,
    }
  },
})
</script>
