<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
    <v-autocomplete
      v-model="innerValue"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="customNoData" #no-data>
        <slot name="no-data" />
      </template>
      <template v-if="customSelection" #selection="{ attr, on, item, selected }">
        <slot name="selection" :attr="attr" :on="on" :item="item" :selected="selected" />
      </template>
      <template v-if="customItem" #item="{ item }">
        <slot name="item" :item="item" />
      </template>
      <template v-if="customAppendOuter" #append-outer>
        <slot name="append-outer" />
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'VAutocompleteWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    customAppendOuter: Boolean,
    customNoData: Boolean,
    customItem: Boolean,
    customSelection: Boolean,
  },
  setup(props, { emit }) {
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(_ => props.value, newVal => innerValue.value = newVal)

    return {
      innerValue,
    }
  },
})
</script>
