<template>
  <v-app-bar
    id="core-toolbar"
    dense
    app
    class="ma-4 rounded-lg"
    elevate-on-scroll
    height="75"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
  >
    <!-- <div class="v-toolbar-title"> -->
    <v-toolbar-title class="d-flex align-center font-weight-light">
      <v-btn class="d-inline default v-btn--simple" icon @click.stop="toggleDrawer">
        <v-icon v-if="drawer">mdi-dots-vertical</v-icon>
        <v-icon v-else>mdi-view-list</v-icon>
      </v-btn>
      <h1 class="d-inline-flex title ma-0">{{ title }}</h1>
    </v-toolbar-title>
    <!-- </div> -->

    <v-spacer />
    <div v-if="isAuthenticated">
      <!-- <v-text-field
        class="mr-4 purple-input"
        label="Search..."
        hide-details
        color="purple"
      /> -->
      <router-link to="/">
        <v-btn icon>
          <v-icon color="tertiary">mdi-view-dashboard</v-icon>
        </v-btn>
      </router-link>

      <v-tooltip bottom>
        <template #activator="{ on }">
          <DarkMode :modes="['light', 'dark']" @change-mode="changeColorMode">
            <template #default="{ mode }">
              <v-btn icon v-on="on">
                <v-icon v-show="mode === 'light'" color="tertiary"> mdi-weather-night </v-icon>
                <v-icon v-show="mode === 'dark'" color="tertiary"> mdi-weather-sunny </v-icon>
              </v-btn>
            </template>
          </DarkMode>
        </template>
        <span>Change Color Mode</span>
      </v-tooltip>

      <v-menu bottom left content-class="dropdown-menu" offset-y transition="slide-y-transition" z-index="12">
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge color="error" overlap>
              <template slot="badge">
                {{ notifications.length }}
              </template>
              <v-icon color="tertiary">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item v-for="notification in notifications" :key="notification">
              <v-list-item-title v-text="notification" />
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        left
        content-class="dropdown-menu"
        offset-y
        transition="slide-y-transition"
        min-width="200"
        z-index="12"
      >
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="tertiary">mdi-account</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item to="/user-profile">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/settings">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item to="/logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const notifications = [
      'Mike, John responded to your email',
      'You have 5 new tasks',
      "You're now a friend with Andrew",
      'Another Notification',
      'Another One',
    ]
    const title = ref(null)

    const activeRoute = computed(_ => root.$route)

    // store
    const drawer = computed(_ => $store.state.app.drawer)
    const isAuthenticated = computed(_ => $store.getters['auth/isAuthenticated'])
    const toggleDrawer = _ => $store.commit('app/TOGGLE_DRAWER')

    title.value = $route.meta.title

    watch(activeRoute, value => {
      title.value = value.meta.title
    })

    const changeColorMode = mode => {
      $vuetify.theme.dark = mode === 'dark'
    }

    return {
      title,
      drawer,
      notifications,
      isAuthenticated,
      changeColorMode,
      toggleDrawer,
    }
  },
})
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
