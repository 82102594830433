<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Clients')" title-actions>
      <template #titleActions>
        <v-btn to="Clients/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="clients"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
      >
        <template #[`item.name`]="{ item }">
          {{ item.contact_handle.firstname }} {{ item.contact_handle.lastname }}
        </template>

        <template #[`item.organisation`]="{ item }">
          {{ item.contact_handle.organisation }}
        </template>

        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.contact_handle.email}`">{{ item.contact_handle.email }}</a>
        </template>

        <template #[`item.actions`]="{ item }">
          <OverviewActions type="clients" :item-id="item.id" info edit remove @onRemove="remove" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import OverviewWrapper from '@/layouts/OverviewWrapper'
import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'ViewClients',
  components: {
    OverviewWrapper,
    AppCard,
    AppPagination,
    OverviewActions,
  },
  setup(props, { root: { $store, $route }, root }) {
    const page = ref(1)
    const headers = [
      {
        sortable: false,
        text: '#',
        value: 'customer',
      },
      {
        sortable: false,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Organisation',
        value: 'organisation',
      },
      {
        sortable: false,
        text: 'E-Mail',
        value: 'email',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.clients.pending)
    const clients = computed(_ => $store.state.clients.clients)
    const pagination = computed(_ => $store.state.clients.pagination)
    const getClients = page => $store.dispatch('clients/getClients', page)
    const removeClient = clientId => $store.dispatch('clients/removeClient', clientId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getClients(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getClients(page.value)
    })

    const remove = id => {
      if (confirm('Sicher, dass der Client gelöscht werden soll?')) {
        if (pending.value) {
          return
        }
        removeClient(id)
          .then(_ => {
            addToast({
              msg: 'Kunde erfolgreich gelöscht',
              type: 'error',
            })

            getClients(page.value)
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      clients,
      headers,
      pagination,
      remove,
    }
  },
})
</script>
