<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :prev-route="prevRoute" :title="$t('Personal Data / Handles')">
          <v-expansion-panels :value="0">
            <template v-for="{ label, key } in handleTypes">
              <!-- TODO: does i need this check? -->
              <!-- <v-expansion-panel v-if="!_.isUndefined(client[`${key}_handle`])" :key="key"> -->
              <v-expansion-panel :key="key">
                <v-expansion-panel-header>
                  {{ $t(label) }}
                  <v-chip
                    v-if="client[`${key}_handle`].active"
                    x-small
                    class="flex-grow-0 ml-4"
                    color="success"
                    text-color="white"
                  >
                    {{ $t('activated') }}
                  </v-chip>
                  <v-chip v-else x-small class="flex-grow-0 ml-4" color="error" text-color="white">
                    {{ $t('deactivated') }}
                  </v-chip>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <address>
                    <b v-if="client[`${key}_handle`].organisation">{{ client[`${key}_handle`].organisation }}</b>
                    <br />
                    <span v-if="client[`${key}_handle`].sex == 'MALE'">Herr </span>
                    <span v-if="client[`${key}_handle`].sex == 'FEMALE'">Frau </span>
                    {{ client[`${key}_handle`].firstname }}
                    {{ client[`${key}_handle`].lastname }}<br />
                    {{ client[`${key}_handle`].street }}
                    {{ client[`${key}_handle`].number }}<br />
                    {{ client[`${key}_handle`].zip }}
                    {{ client[`${key}_handle`].city }}<br />
                    {{ client[`${key}_handle`].country }}<br />
                    <b>Phone:</b> {{ client[`${key}_handle`].phone }}<br />
                    <b>Fax:</b> {{ client[`${key}_handle`].fax }}<br />
                    {{ client[`${key}_handle`].email }}<br />
                    {{ client[`${key}_handle`].unique_name }}
                  </address>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </AppCard>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :title="$t('Sales')">
          <p>Umsatz anzeigen</p>
        </AppCard>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <AppCard :loading="pending" :title="$t('Actions')">
          <p>
            Bearbeiten <br />
            Löschen <br />
            Sperren / Entsperren <br />
            Nachricht senden <br />
            Ticket eröffnen <br />
            Rechnung schreiben
          </p>
        </AppCard>
      </v-col>
      <v-col cols="12" sm="6" lg="6" xl="3">
        <AppCard :loading="pending" :title="$t('Notes')">
          <v-form @submit.prevent="onSubmitNotes">
            <v-container py-0>
              <v-row wrap>
                <v-col cols="12">
                  <v-textarea v-model="notes" label="Notes" />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn class="mx-0 font-weight-light" color="success">Update Notes</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <AppCard :loading="pending" :title="$t('Invoices')">
          <p>Auflistungen der Rechnung mit Rechnungsnr, Betrag, Status, Datum</p>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <AppCard :loading="pending" :title="$t('Products')">
          <p>Auflistung laufender Produkte, z.B. Domain</p>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <AppCard :loading="pending" :title="$t('Files')">
          <p>Kunden spezifische Datein, z.B. Veträge</p>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewClient',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const notes = ref('')
    const prevRoute = ref('/Clients')
    const handleTypes = [
      {
        key: 'contact',
        label: 'Contact Handle',
      },
      {
        key: 'billing',
        label: 'Billing Handle',
      },
      {
        key: 'admin',
        label: 'Admin Handle',
      },
    ]

    // store
    const pending = computed(_ => $store.state.client.pending)
    const client = computed(_ => $store.state.client.client)
    const getClient = _ => $store.dispatch('client/getClient')
    const clearClient = _ => $store.dispatch('client/clearClient')

    getClient()

    onBeforeUnmount(_ => {
      clearClient()
    })

    const onSubmitNotes = _ => {
      // TODO: saveNotes
    }

    return {
      notes,
      prevRoute,
      handleTypes,
      pending,
      client,
      onSubmitNotes,
    }
  },
})
</script>
