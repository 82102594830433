<template>
  <ItemWrapper>
    <AppCard
      :title="`${$t('Quote')} ${!_.isNull(quote.quote_nr) ? quote.quote_nr : ''}`"
      :prev-route="prevRoute"
      :loading="pending"
    >
      <v-row v-if="!pending" justify-start wrap>
        <v-col cols="12" sm="4">
          <v-card flat tile>
            <v-card-title class="py-2">{{ $t('Provider') }}</v-card-title>
            <v-card-text>
              <address>
                <template v-if="tenant_handle.organisation">
                  <span class="font-weight-bold">{{ tenant_handle.organisation }}</span>
                  <br />
                </template>
                <span v-if="tenant_handle.sex == 'MALE'">Herr </span>
                <span v-if="tenant_handle.sex == 'FEMALE'">Frau </span>
                {{ tenant_handle.firstname }} {{ tenant_handle.lastname }}<br />
                {{ tenant_handle.street }} {{ tenant_handle.number }}<br />
                {{ tenant_handle.zip }} {{ tenant_handle.city }}<br />
                {{ tenant_handle.country }}<br />
                <b>Phone:</b> {{ tenant_handle.phone }}<br />
                <b>Fax:</b> {{ tenant_handle.fax }}<br />
                {{ tenant_handle.email }}<br />
              </address>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card flat tile>
            <v-card-title class="py-2">{{ $t('Client') }}</v-card-title>
            <v-card-text>
              <address>
                <template v-if="client_handle.organisation">
                  <span class="font-weight-bold">{{ client_handle.organisation }}</span>
                  <br />
                </template>
                <span v-if="client_handle.sex == 'MALE'">Herr </span>
                <span v-if="client_handle.sex == 'FEMALE'">Frau </span>
                {{ client_handle.firstname }} {{ client_handle.lastname }}<br />
                {{ client_handle.street }} {{ client_handle.number }}<br />
                {{ client_handle.zip }} {{ client_handle.city }}<br />
                {{ client_handle.country }}<br />
                <b>Phone:</b> {{ client_handle.phone }}<br />
                <b>Fax:</b> {{ client_handle.fax }}<br />
                {{ client_handle.email }}<br />
              </address>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card flat tile>
            <v-card-title class="py-2">{{ $t('Infos') }}</v-card-title>
            <v-card-text>
              <p v-if="quote.quote_nr">
                <b>Quote:</b><br />
                <span>{{ quote.quote_nr }}</span>
                <br />
              </p>
              <p>
                <b>Quote Date:</b><br />
                <span>{{ quote.quotedate | moment('DD.MM.YYYY') }}</span>
                <br />
              </p>
              <p>
                <b>Angebot gültig bis:</b><br />
                <span>{{ quote.quotedate | moment('add', `${quote.quotetime} days`, 'DD.MM.YYYY') }}</span>
                <br />
              </p>
              <p>
                <b>Account:</b><br />
                <span>{{ quote.client.customer }}</span>
                <br />
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify-start>
        <v-col cols="12">
          <v-card flat tile>
            <v-card-title class="py-2">{{ $t('Products') }}</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="quote.quote_products"
                :loading="pending"
                :hide-default-footer="quote.quote_products.length <= 5"
              >
                <template #[`item.netto`]="{ item }">
                  {{ (item.amount * item.quantity * (100 - item.discount)) / 100 }}
                  €
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify-start wrap>
        <v-col cols="12" sm="6">
          <p v-if="quote.payment_method">Payment Methods:</p>
          <!-- <img src="../../dist/img/credit/visa.png" alt="Visa">
                  <img src="../../dist/img/credit/mastercard.png" alt="Mastercard">
                  <img src="../../dist/img/credit/american-express.png" alt="American Express">
                  <img src="../../dist/img/credit/paypal2.png" alt="Paypal"> -->

          <v-card flat tile>
            <v-card-title class="py-2">{{ $t('Angebotstext') }}</v-card-title>
            <v-card-text>
              <p>
                {{ quote.extratext }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-simple-table>
            <template #default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">Subtotal:</td>
                  <td>{{ parseFloat(totals.netto.toFixed(2)) }} €</td>
                </tr>
                <tr v-for="(tax, key) in totals.tax" :key="key">
                  <td class="font-weight-bold">MwSt. {{ key }}%</td>
                  <td>{{ parseFloat(tax.toFixed(2)) }} €</td>
                </tr>
                <!-- <tr>
                        <td class="font-weight-bold">Shipping:</td>
                        <td>5.80 €</td>
                      </tr> -->
                <tr>
                  <td class="font-weight-bold">Total:</td>
                  <td>{{ parseFloat(totals.total.toFixed(2)) }} €</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row justify-start wrap>
        <v-col cols="12" sm="6">
          <v-btn :loading="pendingPDF" color="info" @click="downloadPdf">
            <v-icon>mdi-download</v-icon> Download PDF
          </v-btn>
          <!-- <button type="button" class="btn btn-success pull-right"><i class="fa fa-credit-card"></i> Submit Payment
                  </button> -->
        </v-col>

        <v-col cols="12" sm="6" class="text-sm-right">
          <v-btn color="info" :loading="pendingPDF" @click="showPdf">
            <v-icon>mdi-file-pdf-outline</v-icon> Zeige PDF
          </v-btn>
        </v-col>
      </v-row>

      <AppDialog :is-open="visiblePdf" max-width="800px" @onCloseDialog="visiblePdf = false">
        <template slot="title">View PDF</template>

        <v-responsive :aspect-ratio="210 / 297">
          <object :data="`data:application/pdf;base64,${quotePdf}`" type="application/pdf" width="100%" height="100%" />
        </v-responsive>
      </AppDialog>
    </AppCard>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { isUndefined } from 'lodash'

import AppCard from '@/components/UI/AppCard'
import AppDialog from '@/components/UI/AppDialog'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewQuote',
  components: {
    AppCard,
    AppDialog,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const prevRoute = ref('/Quotes')
    const visiblePdf = ref(false)
    const headers = [
      { text: '#', value: 'artNr' },
      { text: 'Product', value: 'title' },
      { text: 'Description', value: 'description' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Amount', value: 'amount' },
      { text: 'Discount', value: 'discount' },
      { text: 'Tax', value: 'tax' },
      { text: 'Netto', value: 'netto', sortable: false },
    ]

    // store
    const pending = computed(_ => $store.state.quote.pending)
    const quote = computed(_ => $store.state.quote.quote)
    const pendingPDF = computed(_ => $store.state.quote.pendingPDF)
    const quotePdf = computed(_ => $store.state.quote.quotePdf)
    const getQuote = _ => $store.dispatch('quote/getQuote')
    const getQuotePdf = quoteId => $store.dispatch('quote/getQuotePdf', quoteId)
    const clearQuote = _ => $store.dispatch('quote/clearQuote')

    getQuote()

    const totals = computed(_ => {
      const totals = {
        netto: 0,
        tax: {},
        maxTax: 0,
        total: 0,
      }

      for (let i = 0; i < quote.value.quote_products.length; i++) {
        const product = quote.value.quote_products[i]
        const netto =
            (product.amount * product.quantity * (100 - product.discount)) / 100
        totals.netto += netto
        const tax = (netto / 100) * product.tax
        if (product.tax !== 0) {
          if (isUndefined(totals.tax[product.tax])) totals.tax[product.tax] = 0
          totals.tax[product.tax] += tax
        }
        totals.maxTax += tax
      }

      totals.total = totals.netto + totals.maxTax

      return totals
    })

    const totalTax = computed(_=> {
      let maxTax = 0
      for (let i = 0; i < quote.value.quote_products.length; i++) {
        const product = quote.value.quote_products[i]
        maxTax += product.amount * (product.tax / 100)
      }
      return parseFloat(maxTax.toFixed(2))
    })

    const tenant_handle = computed(_ => quote.value.tenant.billing_handle)
    const client_handle = computed(_ => quote.value.client.billing_handle)
    const products_lenght = computed(_ =>quote.value.quote_products.length)

    const showPdf = _ => {
      getQuotePdf(quote.value.id).then(_ => (visiblePdf.value = true))
    }
    const downloadPdf = _ =>{
      getQuotePdf(quote.value.id).then(
          _ => {
            const link = document.createElement('a')
            link.download = `${quote.value.quote_nr}.pdf` || 'angebot_entwurf.pdf'
            link.href = 'data:application/octet-stream;base64,' + quotePdf.value
            link.click()
          },
        )
    }

    onUnmounted(_ => {
      clearQuote()
    })

    return {
      prevRoute,
      visiblePdf,
      headers,
      totals,
      pending,
      pendingPDF,
      quote,
      totalTax,
      tenant_handle,
      client_handle,
      products_lenght,
      showPdf,
      downloadPdf,
      quotePdf,
    }
  },
})
</script>
