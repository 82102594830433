const state = {
  invoices: [],
  pagination: {},
  pending: false,
  errors: {},
}

const getters = {}

const actions = {
  getInvoices({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_INVOICES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_INVOICES_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  setStatus({ commit }, invoice) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)

      this.api.invoice
        .setStatus(invoice)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('UPDATE_STATUS_FAIL', data.errors)
            resolve()
          }
          commit('UPDATE_STATUS_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('UPDATE_STATUS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  deleteInvoice({ commit, rootState }, invoiceId) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, invoiceId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('DELETE_INVOICE_FAIL', data.errors)
            resolve()
          }
          commit('DELETE_INVOICE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('DELETE_INVOICE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  UPDATE_STATUS_SUCCESS() {
    return true
  },
  UPDATE_STATUS_FAIL(state, errors) {
    state.errors = errors
  },
  GET_INVOICES_SUCCESS(state, { pagination, invoices }) {
    state.errors = {}
    state.invoices = invoices
    state.pagination = pagination
  },
  GET_INVOICES_FAIL(state, errors) {
    state.errors = errors
  },
  DELETE_INVOICE_SUCCESS(state) {
    state.errors = {}
  },
  DELETE_INVOICE_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
