import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

import LoginView from '@/views/Account/Login'
import DashboardView from '@/views/Dashboard/Dashboard'
import NotFoundComponent from '@/views/NotFoundComponent'
import DummyView from '@/views/Dev/Dummy'

Vue.use(Router)

const createRouter = () =>
  new Router({
    mode: 'history',
    routes: [
      {
        path: '*',
        component: NotFoundComponent,
        meta: {
          title: 404,
          requiresAuth: true,
        },
      },
      {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
        meta: {
          title: 'Dashboard',
          requiresAuth: true,
        },
      },
      {
        path: '/dummy',
        name: 'dummy',
        component: DummyView,
        meta: {
          title: 'Dummy',
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (process.env.NODE_ENV === 'production') {
            next({ name: 'dashboard' })
          } else {
            next()
          }
        },
      },
      {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
          title: 'Login',
          requiresAnon: true,
        },
      },
      {
        path: '/logout',
        name: 'logout',
        beforeEnter: (to, from, next) => {
          store.dispatch('auth/logout')
            .catch(err => err)
            .finally(_ => {
              next({ name: 'login' })
              resetRouter()
            })
        },
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      return { x: 0, y: 0 }
    },
    linkExactActiveClass: 'active',
    linkActiveClass: 'active-path',
  })

const router = createRouter()

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      })
    }
  } else if (to.matched.some(record => record.meta.requiresAnon)) {
    if (store.getters['auth/isAuthenticated']) {
      next({ name: 'dashboard' })
    }
  }

  document.title = `${to.meta.title} | ${store.state.app.appName}` || store.state.app.appName

  next()
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
