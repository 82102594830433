<template>
  <Login :loading="pending" />
</template>

<script>
import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api'

import Login from '@/components/auth/login'

const body = document.body

export default defineComponent({
  name: 'ViewLogin',
  components: {
    Login,
  },
  setup(props, { root: { $store } }) {
    body.classList.add('login-page')

    const pending = computed(_ => $store.state.auth.pending)

    onBeforeUnmount(_ => {
      body.classList.remove('login-page')
    })

    return {
      pending,
    }
  },
})
</script>
