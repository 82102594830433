const state = {
  clients: [],
  pagination: {},
  pending: false,
  errors: {},
}

const getters = {}

const actions = {
  getClients({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_CLIENTS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_CLIENTS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeClient({ commit, rootState }, clientId) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDING', true)
      const { type } = rootState.route.meta

      this.api.item
        .removeItem(type, clientId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_CLIENT_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_CLIENT_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_CLIENT_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  GET_CLIENTS_SUCCESS(state, { pagination, clients }) {
    state.errors = {}
    state.clients = clients
    state.pagination = pagination
  },
  GET_CLIENTS_FAIL(state, errors) {
    state.errors = errors
  },
  REMOVE_CLIENT_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_CLIENT_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
