import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'

Vue.use(VueI18n)

localize({
  en,
  de,
})

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 1,
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 1,
    },
  },
}

// load vee-validate locale
function loadLocale(code) {
  // const locale = lc.getLanguageCode(code.replace(/_/g, '-'))
  localize(code)
}

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const i18n = new VueI18n({
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats,
})

export const setI18nLanguage = lang => {
  i18n.locale = lang
  loadLocale(lang)
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}
