<template>
  <v-row class="voucher_item-row" wrap>
    <v-col cols="12" sm="5" md="5">
      <VTextFieldWithValidation
        v-model="voucherItem.type"
        label="Type"
        rules="required"
        name="type"
        :vid="`type-${index}`"
        placeholder="Type"
      />
    </v-col>

    <v-col cols="12" sm="3" md="3">
      <VSelectWithValidation
        v-model="voucherItem.tax"
        rules="required"
        label="Tax"
        :items="taxes"
        :vid="`tax-${index}`"
        menu-props="offset-y"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="6" sm="3" md="3">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="voucherItem.amount"
        label="Amount"
        name="amount"
        :vid="`amount-${index}`"
        rules="required|decimal|min_value:0"
        placeholder="Amount"
      />
    </v-col>

    <v-col cols="2" sm="1" class="text-right">
      <div class="actions">
        <v-btn color="danger" fab x-small dark @click="removeItem">
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </div>
    </v-col>

    <v-col v-if="length > index + 1" cols="12">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import { taxes } from '@/config'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default {
  name: 'VoucherItemRow',
  components: {
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    voucherItem: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      taxes: taxes,
    }
  },
  methods: {
    removeItem() {
      this.$emit('removeItem', this.index)
    },
  },
}
</script>
