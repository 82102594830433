var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"ma-4 rounded-lg",attrs:{"id":"core-toolbar","dense":"","app":"","elevate-on-scroll":"","height":"75","color":_vm.$vuetify.theme.dark ? 'dark' : 'white'}},[_c('v-toolbar-title',{staticClass:"d-flex align-center font-weight-light"},[_c('v-btn',{staticClass:"d-inline default v-btn--simple",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}},[(_vm.drawer)?_c('v-icon',[_vm._v("mdi-dots-vertical")]):_c('v-icon',[_vm._v("mdi-view-list")])],1),_c('h1',{staticClass:"d-inline-flex title ma-0"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),(_vm.isAuthenticated)?_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-view-dashboard")])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('DarkMode',{attrs:{"modes":['light', 'dark']},on:{"change-mode":_vm.changeColorMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mode = ref.mode;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(mode === 'light'),expression:"mode === 'light'"}],attrs:{"color":"tertiary"}},[_vm._v(" mdi-weather-night ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(mode === 'dark'),expression:"mode === 'dark'"}],attrs:{"color":"tertiary"}},[_vm._v(" mdi-weather-sunny ")])],1)]}}],null,true)})]}}],null,false,3388393018)},[_c('span',[_vm._v("Change Color Mode")])]),_c('v-menu',{attrs:{"bottom":"","left":"","content-class":"dropdown-menu","offset-y":"","transition":"slide-y-transition","z-index":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-badge',{attrs:{"color":"error","overlap":""}},[_c('template',{slot:"badge"},[_vm._v(" "+_vm._s(_vm.notifications.length)+" ")]),_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-bell")])],2)],1)]}}],null,false,24495309)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.notifications),function(notification){return _c('v-list-item',{key:notification},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(notification)}})],1)}),1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","content-class":"dropdown-menu","offset-y":"","transition":"slide-y-transition","min-width":"200","z-index":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-account")])],1)]}}],null,false,2450427683)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/user-profile"}},[_c('v-list-item-title',[_vm._v("Profile")])],1),_c('v-list-item',{attrs:{"to":"/settings"}},[_c('v-list-item-title',[_vm._v("Settings")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/logout"}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }