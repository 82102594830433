<template>
  <v-row class="subscription_product-row" wrap>
    <v-col cols="6" sm="3" md="2" lg="1">
      <SearchSelectByArtNr
        v-if="!subscriptionProduct.isDirty"
        :key="`nr-${index}`"
        v-model="subscriptionProduct.artNr"
        :vid="`nr-${index}`"
        @input="setArtNr"
      />
      <VTextFieldWithValidation
        v-else
        v-model="subscriptionProduct.artNr"
        label="Nr"
        name="nr"
        :vid="`nr-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Nr"
        disabled
      />
    </v-col>

    <v-col cols="6" sm="9" md="3" lg="4">
      <SearchSelectByTitle
        v-if="!subscriptionProduct.isDirty"
        :key="`name-${index}`"
        v-model="subscriptionProduct.name"
        :vid="`name-${index}`"
        @input="setName"
      />

      <VTextFieldWithValidation
        v-else
        v-model="subscriptionProduct.name"
        label="Name"
        name="name"
        :vid="`name-${index}`"
        rules="required"
        placeholder="Name"
      />
    </v-col>

    <v-col cols="12" sm="5" md="4">
      <VTextFieldWithValidation
        v-model="subscriptionProduct.description"
        label="Description"
        name="description"
        :vid="`description-${index}`"
        placeholder="Enter Description"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="subscriptionProduct.price"
        label="Price"
        name="price"
        :vid="`price-${index}`"
        rules="required|decimal|min_value:0"
        placeholder="Price"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="subscriptionProduct.tax"
        label="Tax"
        name="tax"
        :vid="`tax-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="2" sm="1" md="1" class="text-right">
      <div class="actions">
        <!-- // TODO: [Future Feature] Add Save Product to Productlist Button -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn class="pa-1 ml-1 mt-4 remove-button" color="error" icon v-on="on" @click="removeSubscriptionProduct">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Product</span>
        </v-tooltip>
      </div>
    </v-col>

    <v-col v-if="length > index + 1" cols="12">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import SearchSelectByArtNr from '@/components/Products/SearchSelectByArtNr'
import SearchSelectByTitle from '@/components/Products/SearchSelectByTitle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default {
  name: 'SubscriptionProductRow',
  components: {
    SearchSelectByArtNr,
    SearchSelectByTitle,
    VTextFieldWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    subscriptionProduct: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  created() {
    if (this.subscriptionProduct.artNr) {
      this.subscriptionProduct.isDirty = true
    }
  },
  methods: {
    setArtNr(product) {
      Object.assign(this.subscriptionProduct, product)
      this.subscriptionProduct.name = product.title
      this.subscriptionProduct.description = product.subscriptionstext
      this.subscriptionProduct.price = product.price
      this.subscriptionProduct.isDirty = true
    },
    setName(product) {
      Object.assign(this.subscriptionProduct, product)
      this.subscriptionProduct.name = product.title
      this.subscriptionProduct.description = product.subscriptionstext
      this.subscriptionProduct.price = product.price
      this.subscriptionProduct.isDirty = true
    },
    removeSubscriptionProduct() {
      this.$emit('removeSubscriptionProduct', this.index)
    },
  },
}
</script>
