<template>
  <v-icon :color="arrowDirection === 'in' ? 'red' : 'black'">
    {{ directionIcon }}
  </v-icon>
</template>

<script>
// TODO: do we still need it?
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DirectionArrow',
  props: {
    direction: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      arrowDirection: computed(_ => props.direction === 'in'
        ? 'mdi-arrow-down-bold'
        : 'mdi-arrow-up-bold'),
    }
  },
})
</script>
