// Invoice Store

import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, map } from 'lodash'

const invoice = {
  invoice_nr: null,
  client_id: null,
  billingdate: null,
  paymenttime: 14,
  skonto: 0,
  tenant_id: null,
  invoicerange_id: null,
  extratext: null,
  invoice_products: [],
  tenant: {
    billing_handle: {},
  },
}

const state = {
  errors: {},
  invoice: cloneDeep(invoice),
  invoicePdf: null,
  invoicePresets: {},
  pending: false,
  pendingPDF: false,
  pendingPresets: false,
  pendingSave: false,
}

const getters = {
  getField,
}

const actions = {
  getInvoice({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_INVOICE_SUCCESS', data.invoice)
          resolve()
        })
        .catch(error => {
          commit('GET_INVOICE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getInvoicePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGPDF', true)

      this.api.invoice
        .getPdf(id)
        .then(({ data }) => {
          commit('GET_INVOICE_PDF_SUCCESS', data.invoice)
          resolve()
        })
        .catch(error => {
          commit('GET_INVOICE_PDF_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGPDF', false)
        })
    })
  },
  getEditInvoice({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          const invoice = cloneDeep(data).invoice
          invoice.invoice_products = map(invoice.invoice_products, p => {
            if (p.artNr) {
              p.isDirty = true
            }
            return p
          })
          commit('GET_INVOICE_SUCCESS', invoice)
          commit('GET_INVOICE_PRESETS_SUCCESS', { invoiceranges: data.invoiceranges, tenants: data.tenants })
          resolve()
        })
        .catch(error => {
          commit('GET_INVOICE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getInvoicePresets({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGPRESETS', false)
      const { type } = rootState.route.meta

      this.api.item
        .getOptions(type)
        .then(({ data }) => {
          commit('GET_INVOICE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_INVOICE_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGPRESETS', false)
        })
    })
  },
  createInvoice({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      delete state.invoice.tenant

      this.api.item
        .addItem(type, state.invoice)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_INVOICE_FAIL', data.errors)
            return
          }
          commit('ADD_INVOICE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_INVOICE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editInvoice({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDINGSAVE', true)

      this.api.item
        .editItem(type, state.invoice)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_INVOICE_SUCCESS', data.errors)
            return
          }
          commit('EDIT_INVOICE_SUCCESS', data.invoice)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_INVOICE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  addInvoiceProduct({ commit }) {
    commit('ADD_INVOICE_PRODUCT')
  },
  removeInvoiceProduct({ commit }, index) {
    commit('REMOVE_INVOICE_PRODUCT', index)
  },
  clearInvoice({ commit }) {
    commit('CLEAR_INVOICE')
  },
}

const mutations = {
  CLEAR_INVOICE(state) {
    state.invoice = cloneDeep(invoice)
    state.invoicePdf = null
    state.invoicePresets = {}
    state.errors = {}
  },
  ADD_INVOICE_PRODUCT(state) {
    state.invoice.invoice_products.push({
      artNr: null,
      quantity: null,
      amount: null,
      title: null,
      description: null,
      tax: 19,
      discount: null,
      isDirty: false,
    })
  },
  REMOVE_INVOICE_PRODUCT(state, index) {
    state.invoice.invoice_products.splice(index, 1)
  },
  GET_INVOICE_SUCCESS(state, data) {
    state.errors = {}
    state.invoice = data
  },
  GET_INVOICE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_INVOICE_SUCCESS(state) {
    state.errors = {}
  },
  ADD_INVOICE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_INVOICE_PRESETS_SUCCESS(state, data) {
    state.invoicePresets = data
    state.errors = {}
  },
  GET_INVOICE_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_INVOICE_SUCCESS(state, data) {
    state.errors = {}
    state.invoice = data
  },
  EDIT_INVOICE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_INVOICE_PDF_SUCCESS(state, data) {
    state.errors = {}
    state.invoicePdf = data
  },
  GET_INVOICE_PDF_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingPDF',
  'pendingPresets',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
