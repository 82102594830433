var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OverviewWrapper',[_c('AppCard',{attrs:{"title":_vm.$t('All Vouchers'),"title-actions":""},scopedSlots:_vm._u([{key:"titleActions",fn:function(){return [_c('v-btn',{attrs:{"to":"Vouchers/create","color":"secondary","fab":"","small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}])},[(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination,"location":"top"}}):_vm._e(),_c('v-data-table',{staticClass:"v-data-table__overview",attrs:{"headers":_vm.headers,"items":_vm.vouchers,"page":_vm.pagination ? _vm.pagination.page : _vm.page,"items-per-page":_vm.pagination ? _vm.pagination.perPage : 12,"loading":_vm.pending,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('DirectionArrow',{attrs:{"direction":item.direction}})]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'clients-item',
            params: { id: item.handle.clients_id },
          }}},[(item.handle.organisation)?_c('b',[_vm._v(" "+_vm._s(item.handle.organisation)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.handle.firstname)+" "+_vm._s(item.handle.lastname)+" ")])]}},{key:"item.voucher_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.voucher_date,'DD.MM.YYYY'))+" ")]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_c('i18n-n',{attrs:{"value":item.amount,"format":{ key: 'currency', currency: item.currency }}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('OverviewActions',{attrs:{"type":"vouchers","item-id":item.id,"info":"","edit":"","remove":""},on:{"onRemove":_vm.remove}})]}}],null,true)}),(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }