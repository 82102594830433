<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Create Domain')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="7" sm="9">
                        <VTextFieldWithValidation
                          v-model="name"
                          rules="required"
                          label="Domainname"
                          name="domainname"
                          placeholder="Enter Domainname"
                          :messages="domainCheckMessage"
                          :loading="pendingDomainCheck"
                          custom-prepend
                        >
                          <template #prepend class="flex-shrink-0">
                            <small class="mt-2">www.</small>
                          </template>
                        </VTextFieldWithValidation>
                      </v-col>

                      <v-col cols="5" sm="3">
                        <VSelectWithValidation
                          v-model="tld"
                          rules="required"
                          label="TLD"
                          :items="tlds"
                          menu-props="offset-y"
                          placeholder="Select tld"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="status === 'unavailable' && name" cols="12">
                    <VTextFieldWithValidation
                      v-model="auth_code"
                      rules="required"
                      label="Auth code"
                      name="auth_code"
                      placeholder="Enter Auth Code"
                    />
                  </v-col>

                  <v-col cols="12">
                    <SearchSelectClient v-model="client_id" rules="required" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle v-model="owner_handle_id" rules="required" handle-type="Owner" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle v-model="admin_handle_id" rules="required" handle-type="Admin" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle v-model="tech_handle_id" rules="required" handle-type="Tech" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectHandle v-model="zone_handle_id" rules="required" handle-type="Zone" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="ns1"
                      rules="required"
                      label="NS1"
                      name="ns1"
                      placeholder="Enter NS1"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="ns2"
                      rules="required"
                      label="NS2"
                      name="ns2"
                      placeholder="Enter NS2"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns3" label="NS3" name="ns3" placeholder="Enter NS3" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns4" label="NS4" name="ns4" placeholder="Enter NS4" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation v-model="ns5" label="NS5" name="ns5" placeholder="Enter NS5" />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { tlds } from '@/config'
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import SearchSelectHandle from '@/components/Handles/SearchSelectHandle'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateDomain',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    SearchSelectClient,
    SearchSelectHandle,
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Domains')
    const delayTimer = ref(null)

    // store
    const pending = computed(_ => $store.state.domain.pending)
    const pendingDomainCheck = computed(_ => $store.state.domain.pendingDomainCheck)
    const status = computed(_ => $store.state.domain.status)
    const domain = computed(_ => $store.state.domain.domain)
    const errors = computed(_ => $store.state.domain.errors)
    const createDomain = _ => $store.dispatch('domain/createDomain')
    const clearDomain = _ => $store.dispatch('domain/clearDomain')
    const checkDomain = domain => $store.dispatch('domain/checkDomain', domain)
    const clearStatus = _ => $store.dispatch('domain/clearStatus')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const domainCheckMessage = computed(_ => {
      let message = ''
      if (status.value === 'unavailable') {
        message = 'Domain ist nicht verfügbar! Besitzen Sie die Domain selbst?'
      } else if (status.value === 'available') {
        message = 'Domain ist verfügbar!'
      }
      return message
    })

    watch(_ => [
      domain.value.name,
      domain.value.tld,
    ], (val, oldVal) => {
      onDomainCheck()
      if (!val[0]) {
        clearStatus()
      }

    })

    const onDomainCheck = _ => {
      if (domain.value.name && domain.value.name.length >= 3 && domain.value.tld) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          const tempDomain = {
            tld: domain.value.tld,
            sld: domain.value.name,
          }
          checkDomain(tempDomain)
        }, 500)
      }
    }

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createDomain().then(({ domain, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Domain erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'domains-item',
            params: { id: domain.id },
          })
        } else {
          $router.push({ path: '/Domains' })
        }
      })
    }

    onUnmounted(_ => {
      clearDomain()
    })

    return {
      tlds,
      status,
      pending,
      pendingDomainCheck,
      errors,
      prevRoute,
      domainCheckMessage,
      submitForm,
      ...mapFields('domain', [
        'domain.client_id',
        'domain.name',
        'domain.tld',
        'domain.auth_code',
        'domain.owner_handle_id',
        'domain.admin_handle_id',
        'domain.tech_handle_id',
        'domain.zone_handle_id',
        'domain.ns1',
        'domain.ns2',
        'domain.ns3',
        'domain.ns4',
        'domain.ns5',
      ]),
    }
  },
})
</script>
