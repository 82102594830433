const state = {
  handles: [],
  pagination: {},
  pending: false,
  error: {},
}

const getters = {}

const actions = {
  getHandles({ commit, rootState }, page) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.items
        .getItems(type, page)
        .then(({ data }) => {
          commit('GET_HANDLES_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_HANDLES_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  removeHandle({ commit, rootState }, handleId) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      this.api.item
        .removeItem(type, handleId)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('REMOVE_HANDLE_FAIL', data.errors)
            resolve()
          }
          commit('REMOVE_HANDLE_SUCCESS', data)
          resolve(data)
        })
        .catch(error => {
          commit('REMOVE_HANDLE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
}

const mutations = {
  SET_PENDING(state, val) {
    state.pending = val
  },
  GET_HANDLES_SUCCESS(state, { pagination, handles }) {
    state.errors = {}
    state.handles = handles
    state.pagination = pagination
  },
  GET_HANDLES_FAIL(state, errors) {
    state.error = errors
  },
  REMOVE_HANDLE_SUCCESS(state) {
    state.errors = {}
  },
  REMOVE_HANDLE_FAIL(state, errors) {
    state.errors = errors
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
