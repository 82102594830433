var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OverviewWrapper',[_c('AppCard',{attrs:{"title":_vm.$t('All Clients'),"title-actions":""},scopedSlots:_vm._u([{key:"titleActions",fn:function(){return [_c('v-btn',{attrs:{"to":"Clients/create","color":"secondary","fab":"","small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}])},[(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination,"location":"top"}}):_vm._e(),_c('v-data-table',{staticClass:"v-data-table__overview",attrs:{"headers":_vm.headers,"items":_vm.clients,"page":_vm.pagination.page,"items-per-page":_vm.pagination.perPage,"loading":_vm.pending,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact_handle.firstname)+" "+_vm._s(item.contact_handle.lastname)+" ")]}},{key:"item.organisation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact_handle.organisation)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.contact_handle.email))}},[_vm._v(_vm._s(item.contact_handle.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('OverviewActions',{attrs:{"type":"clients","item-id":item.id,"info":"","edit":"","remove":""},on:{"onRemove":_vm.remove}})]}}],null,true)}),(_vm.pagination)?_c('AppPagination',{attrs:{"pagination":_vm.pagination}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }