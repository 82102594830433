<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Create Zone')">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="name"
                      rules="required"
                      label="Name"
                      name="name"
                      placeholder="Enter Name"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="ip"
                      rules="required"
                      label="IP"
                      name="ip"
                      placeholder="Enter IP"
                    />
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <VSelectWithValidation
                          v-model="template"
                          rules="required"
                          label="Template"
                          :items="templatesArray"
                          menu-props="offset-y"
                          placeholder="Select Template"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <VSelectWithValidation
                          v-model="connection"
                          rules="required"
                          label="Connection"
                          :items="connectionsArray"
                          menu-props="offset-y"
                          placeholder="Select Connection"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'
import { isArray } from 'lodash'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateZone',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    VSelectWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Users')

    // store
    const connections = computed(_ => $store.state.zone.connections)
    const templates = computed(_ => $store.state.zone.templates)
    const pending = computed(_ => $store.state.zone.pending)
    const errors = computed(_ => $store.state.zone.errors)
    const createZone = _ => $store.dispatch('zone/createZone')
    const getConnections = _ => $store.dispatch('zone/getConnections')
    const getTemplates = _ => $store.dispatch('zone/getTemplates')
    const clearZone = _ => $store.dispatch('zone/clearZone')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const connectionsArray = computed(_ => parseArray(connections.value))
    const templatesArray = computed(_ => parseArray(templates.value))

    getConnections()
    getTemplates()

    const parseArray = arr => {
      const array = []
      for (const [
            key,
            value,
          ] of Object.entries(arr)) {
        array.push({
          header: key,
        })

        if (isArray(value)) {
          value.forEach(ele => {
            array.push(ele)
          })
        }
      }
      return array
    }

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createZone().then(({ zone, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Zone erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'zones-item',
            params: { id: zone.id },
          })
        } else {
          $router.push({ path: '/Zones' })
        }
      })
    }

    onUnmounted(_ => {
      clearZone()
    })

    return {
      prevRoute,
      connections,
      templates,
      pending,
      errors,
      connectionsArray,
      templatesArray,
      submitForm,
      ...mapFields('zone', [
        'zone.name',
        'zone.ip',
        'zone.template',
        'zone.connection',
      ]),
    }
  },
})
</script>
