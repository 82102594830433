<template>
  <OverviewActions
    type="invoices"
    :item-id="invoice.id"
    info
    :edit="invoice.status === 'draft'"
    :remove="invoice.status === 'draft'"
    :disable-remove="isInvoicePending"
    @onRemove="removeInvoice"
  />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'InvoiceRowActions',
  components: {
    OverviewActions,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    pendingInvoices: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const isInvoicePending = computed(_ => props.pendingInvoices.indexOf(props.invoice.id) !== -1)

    const removeInvoice = _ => {
      emit('onRemove')
    }

    return {
      isInvoicePending,
      removeInvoice,
    }
  },
})
</script>

