<template>
  <div>
    <router-link v-if="info" :to="{ name: `${type}-item`, params: { id: itemId } }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="pa-1 ma-1" color="info" icon v-on="on">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>View</span>
      </v-tooltip>
    </router-link>
    <router-link v-if="edit" :to="{ name: `${type}-edit`, params: { id: itemId } }">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="pa-1 ma-1" color="success" icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </router-link>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-if="remove"
          :disabled="disableRemove"
          class="pa-1 ml-1 remove-button"
          color="error"
          icon
          v-on="on"
          @click="$emit('onRemove', itemId)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <span>Remove</span>
    </v-tooltip>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OverviewActions',
  props: {
    type: {
      type: String,
      default: null,
    },
    itemId: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
    info: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
    },
    remove: {
      type: Boolean,
    },
    disableRemove: {
      type: Boolean,
    },
  },
})
</script>
