// Quote Store

import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, map } from 'lodash'

const quote = {
  amount: 0,
  client_id: null,
  extratext: null,
  quote_nr: null,
  quote_products: [],
  quotedate: null,
  quoterange_id: null,
  quotetime: 14,
  tenant_id: null,
  status: null,
  tenant: {
    billing_handle: {},
  },
}

const state = {
  errors: {},
  quote: cloneDeep(quote),
  quotePdf: null,
  quotePresets: [],
  pending: false,
  pendingPDF: false,
  pendingPresets: false,
  pendingSave: false,
}

const getters = {
  getField,
}

const actions = {
  getQuote({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getItem(type, id)
        .then(({ data }) => {
          commit('GET_QUOTE_SUCCESS', data.quote)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getQuotePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGPDF', true)

      this.api.quote
        .getPdf(id)
        .then(({ data }) => {
          commit('GET_QUOTE_PDF_SUCCESS', data.quote)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_PDF_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGPDF', false)
        })
    })
  },
  getEditQuote({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta
      const { id } = rootState.route.params

      commit('SET_PENDING', true)

      this.api.item
        .getEditItem(type, id)
        .then(({ data }) => {
          const quote = cloneDeep(data).quote
          quote.quote_products = map(quote.quote_products, p => {
            if (p.artNr) {
              p.isDirty = true
            }
            return p
          })
          commit('GET_QUOTE_SUCCESS', quote)
          commit('GET_QUOTE_PRESETS_SUCCESS', { quoteranges: data.quoteranges, tenants: data.tenants })
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  getQuotePresets({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_PENDINGPRESETS', true)

      this.api.quote
        .getQuotePresets()
        .then(({ data }) => {
          commit('GET_QUOTE_PRESETS_SUCCESS', data)
          resolve()
        })
        .catch(error => {
          commit('GET_QUOTE_PRESETS_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGPRESETS', false)
        })
    })
  },
  createQuote({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      commit('SET_PENDING', true)

      delete state.quote.tenant
      delete state.quote.status

      this.api.item
        .addItem(type, state.quote)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('ADD_QUOTE_FAIL', data.errors)
            resolve()
          }
          commit('ADD_QUOTE_SUCCESS')
          resolve(data)
        })
        .catch(error => {
          commit('ADD_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDING', false)
        })
    })
  },
  editQuote({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      const { type } = rootState.route.meta

      delete state.quote.client
      delete state.quote.tenant

      commit('SET_PENDINGSAVE', true)

      this.api.item
        .editItem(type, state.quote)
        .then(({ data }) => {
          if (data && data.errors) {
            commit('EDIT_QUOTE_SUCCESS', data.errors)
            resolve()
          }
          commit('EDIT_QUOTE_SUCCESS', data.quote)
          resolve(data)
        })
        .catch(error => {
          commit('EDIT_QUOTE_FAIL', error)
          reject(error)
        })
        .finally(_ => {
          commit('SET_PENDINGSAVE', false)
        })
    })
  },
  addQuoteProduct({ commit }) {
    commit('ADD_QUOTE_PRODUCT')
  },
  removeQuoteProduct({ commit }, index) {
    commit('REMOVE_QUOTE_PRODUCT', index)
  },
  clearQuote({ commit }) {
    commit('CLEAR_QUOTE')
  },
}

const mutations = {
  CLEAR_QUOTE(state) {
    state.quote = cloneDeep(quote)
    state.quotePdf = null
    state.errors = {}
  },
  ADD_QUOTE_PRODUCT(state) {
    state.quote.quote_products.push({
      artNr: null,
      quantity: null,
      amount: null,
      title: null,
      description: null,
      tax: 19,
      discount: null,
      isDirty: false,
    })
  },
  REMOVE_QUOTE_PRODUCT(state, index) {
    state.quote.quote_products.splice(index, 1)
  },
  GET_QUOTE_SUCCESS(state, data) {
    state.errors = {}
    state.quote = data
  },
  GET_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  ADD_QUOTE_SUCCESS(state) {
    state.errors = {}
  },
  ADD_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_QUOTE_PRESETS_SUCCESS(state, data) {
    state.quotePresets = data
    state.errors = {}
  },
  GET_QUOTE_PRESETS_FAIL(state, errors) {
    state.errors = errors
  },
  EDIT_QUOTE_SUCCESS(state, data) {
    state.errors = {}
    state.quote = data
  },
  EDIT_QUOTE_FAIL(state, errors) {
    state.errors = errors
  },
  GET_QUOTE_PDF_SUCCESS(state, data) {
    state.errors = {}
    state.quotePdf = data
  },
  GET_QUOTE_PDF_FAIL(state, errors) {
    state.errors = errors
  },
  updateField,
}

const object_types = [
  'pending',
  'pendingPDF',
  'pendingPresets',
  'pendingSave',
]

object_types.forEach(object_type => {
  mutations['SET_' + object_type.toUpperCase()] = (state, payload) => {
    state[object_type] = payload
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
