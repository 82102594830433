<template>
  <v-row class="invoice_product-row" wrap>
    <v-col cols="6" sm="2" md="1">
      <SearchSelectByArtNr
        v-if="!invoiceProduct.isDirty"
        :key="`nr-${index}`"
        v-model="invoiceProduct.artNr"
        :vid="`nr-${index}`"
        @input="setArtNr"
      />
      <VTextFieldWithValidation
        v-else
        v-model="invoiceProduct.artNr"
        label="Nr"
        name="nr"
        :vid="`nr-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Nr"
      />
    </v-col>

    <v-col cols="6" sm="2" md="2">
      <SearchSelectByTitle
        v-if="!invoiceProduct.isDirty"
        :key="`title-${index}`"
        v-model="invoiceProduct.title"
        :vid="`title-${index}`"
        @input="setTitle"
      />

      <VTextFieldWithValidation
        v-else
        v-model="invoiceProduct.title"
        label="Title"
        name="title"
        :vid="`title-${index}`"
        rules="required"
        placeholder="Title"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <VTextFieldWithValidation
        v-model="invoiceProduct.description"
        label="Description"
        name="description"
        :vid="`description-${index}`"
        placeholder="Enter Description"
      />
    </v-col>

    <v-col cols="6" sm="2" md="1">
      <VTextFieldWithValidation
        v-model="invoiceProduct.quantity"
        label="Quantity"
        name="quantity"
        :vid="`quantity-${index}`"
        rules="required|decimal"
        placeholder="Quantity"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <!-- price -->
      <VTextFieldWithValidation
        v-model="invoiceProduct.amount"
        label="Amount"
        name="amount"
        :vid="`amount-${index}`"
        rules="required|decimal|min_value:0"
        placeholder="Amount"
      />
    </v-col>

    <v-col cols="6" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="invoiceProduct.tax"
        label="Tax"
        name="tax"
        :vid="`tax-${index}`"
        rules="required|integer|min_value:0"
        placeholder="Tax"
      />
    </v-col>

    <v-col cols="4" sm="3" md="1">
      <VTextFieldWithValidation
        v-model="invoiceProduct.discount"
        label="Discount"
        name="discount"
        :vid="`discount-${index}`"
        rules="integer|min_value:0"
        placeholder="Discount"
      />
    </v-col>

    <v-col cols="2" sm="3" md="1" class="text-right">
      <div class="actions">
        <!-- // TODO: [Future Feature] Add Save Product to Productlist Button -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn class="pa-1 ml-1 mt-4 remove-button" color="error" icon v-on="on" @click="removeInvoiceProduct">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Product</span>
        </v-tooltip>
      </div>
    </v-col>

    <v-col v-if="length > index + 1" cols="12">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import SearchSelectByArtNr from '@/components/Products/SearchSelectByArtNr'
import SearchSelectByTitle from '@/components/Products/SearchSelectByTitle'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'InvoiceProductRow',
  components: {
    SearchSelectByArtNr,
    SearchSelectByTitle,
    VTextFieldWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    invoiceProduct: {
      type: Object,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    if (props.invoiceProduct.artNr) {
      props.invoiceProduct.isDirty = true
    }

    const setArtNr = product => {
      Object.assign(props.invoiceProduct, product)
      props.invoiceProduct.amount = product.price
      props.invoiceProduct.isDirty = true
    }

    const setTitle = product => {
      Object.assign(props.invoiceProduct, product)
      props.invoiceProduct.isDirty = true
    }

    const removeInvoiceProduct = _ => {
      emit('removeInvoiceProduct', props.index)
    }

    return {
      setArtNr,
      setTitle,
      removeInvoiceProduct,
    }
  },
})
</script>
