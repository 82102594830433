<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Invoices')" title-actions>
      <template #titleActions>
        <v-btn to="Invoices/create" color="secondary" fab small dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="invoices"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (['closed', 'storno', 'cancled'].includes(item.status) ? highlightClasses : '')"
      >
        <template #[`item.client`]="{ item }">
          <router-link v-if="item.client_id" :to="{ name: 'clients-item', params: { id: item.client_id } }">
            <b>{{ item.client.contact_handle.organisation }}</b>
            {{ item.client.contact_handle.firstname }}
            {{ item.client.contact_handle.lastname }}
          </router-link>
        </template>

        <template #[`item.billingdate`]="{ item }">
          {{ item.billingdate | moment('DD.MM.YYYY') }}
        </template>

        <template #[`item.due_at`]="{ item }">
          {{ item.billingdate | moment('add', '14 days', 'DD.MM.YYYY') }}
        </template>

        <!-- <template v-slot:item.amount="{ item }">
          {{ item.amount }} €
        </template> -->

        <template #[`item.status`]="{ item }">
          <InvoiceRowStateButtons
            :invoice="item"
            :pending-invoices="pendingInvoices"
            :disabled="pending"
            @onChangeState="changeState(item.id, ...arguments)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <InvoiceRowActions :invoice="item" :pending-invoices="pendingInvoices" @onRemove="remove(item.id)" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import InvoiceRowActions from '@/components/Invoices/InvoiceRowActions'
import InvoiceRowStateButtons from '@/components/Invoices/InvoiceRowStateButtons'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewInvoices',
  components: {
    AppCard,
    AppPagination,
    InvoiceRowActions,
    InvoiceRowStateButtons,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const pendingInvoices = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Invoice Nr.',
        value: 'invoice_nr',
      },
      {
        sortable: false,
        text: 'Billingdate',
        value: 'billingdate',
      },
      {
        sortable: false,
        text: 'Due at',
        value: 'due_at',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const invoices = computed(_ => $store.state.invoices.invoices)
    const pagination = computed(_ => $store.state.invoices.pagination)
    const pending = computed(_ => $store.state.invoices.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getInvoices = page => $store.dispatch('invoices/getInvoices', page)
    const setStatus = status => $store.dispatch('invoices/setStatus', status)
    const deleteInvoice = invoiceId => $store.dispatch('invoices/deleteInvoice', invoiceId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getInvoices(page.value)

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      getInvoices(page.value)
    })

    const changeState = (id, status) => {
      const invoice = {
        id,
        status,
      }

      const pendingButtonIndex = pendingInvoices.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingInvoices.push(id)

      setStatus(invoice)
        .then(response => {
          pendingInvoices.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Status erfolgreich geändert',
            type: 'info',
          })

          getInvoices(page.value)
        })
        .catch(error => {
          addToast({
            msg: 'Statusänderung fehlgeschlagen',
            type: 'error',
          })
          pendingInvoices.splice(pendingButtonIndex, 1)
        })
    }

    const remove = id => {
      const pendingButtonIndex = pendingInvoices.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingInvoices.push(id)

      deleteInvoice(id)
        .then(response => {
          pendingInvoices.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Rechnung erfolgreich gelöscht',
            type: 'error',
          })

          getInvoices(page.value)
        })
        .catch(error => {
          addToast({
            msg: 'Löschung fehlgeschlagen',
            type: 'error',
          })
          pendingInvoices.splice(pendingButtonIndex, 1)
        })
    }

    return {
      page,
      pendingInvoices,
      headers,
      invoices,
      pagination,
      pending,
      highlightClasses,
      changeState,
      remove,
    }
  },
})
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
