<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12" sm="6" lg="4">
        <AppCard :title="`${$t('Voucher Infos')}`" :prev-route="prevRoute" :loading="pending">
          <v-card-text>
            <p>
              <b>Name:</b><br />
              <span>{{ voucher.name }}</span><br />
            </p>
            <p>
              <b>Type:</b><br />
              <span>{{ voucher.type }}</span><br />
            </p>
            <p>
              <b>Status:</b><br />
              <span>{{ voucher.status }}</span><br />
            </p>
            <p>
              <b>Direction:</b><br />
              <span>{{ voucher.direction }}</span><br />
            </p>
            <p>
              <b>Voucher Date:</b><br />
              <span>{{ voucher.voucher_date | moment('DD.MM.YYYY') }}</span><br />
            </p>
            <p v-if="voucher.direction === 'in'">
              <b>Payment Type:</b><br />
              <span>{{ voucher.payment_type }}</span><br />
            </p>
            <p>
              <b>Due Date:</b><br />
              <span>{{ voucher.voucher_duedate | moment('DD.MM.YYYY') }}</span><br />
            </p>
            <p v-if="(voucher.direction === 'in' && voucher.payment_type === 'open') || voucher.direction === 'out'">
              <b>Pay Date:</b><br />
              <span>{{ voucher.voucher_paydate | moment('DD.MM.YYYY') }}</span><br />
            </p>
            <p>
              <b>Amount:</b><br />
              <span>{{ voucher.amount }}</span><br />
            </p>
            <p>
              <b>Currency:</b><br />
              <span>{{ voucher.currency }}</span><br />
            </p>
            <p>
              <b>Last updated:</b><br />
              <span>{{ voucher.voucher_updated | moment('DD.MM.YYYY') }}</span><br />
            </p>
          </v-card-text>
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <AppCard :loading="pending" :title="$t('Handle')">
          <template v-if="voucher.handle.organisation">
            <span class="font-weight-bold">{{ voucher.handle.organisation }}</span><br />
          </template>
          <span v-if="voucher.handle.sex == 'MALE'">Herr </span>
          <span v-if="voucher.handle.sex == 'FEMALE'">Frau </span>
          {{ voucher.handle.firstname }} {{ voucher.handle.lastname }}<br />
          {{ voucher.handle.street }} {{ voucher.handle.number }}<br />
          {{ voucher.handle.zip }} {{ voucher.handle.city }}<br />
          {{ voucher.handle.country }}<br />
          <b>Phone:</b> {{ voucher.handle.phone }}<br />
          <b>Fax:</b> {{ voucher.handle.fax }}<br />
          {{ voucher.handle.email }}<br />
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <AppCard :loading="pending" :title="$t('Description')">
          <p>{{ voucher.description }}</p>
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6">
        <AppCard :loading="pending" :title="$t('Voucher Items')">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Type</th>
                <th>Tax</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in voucher.voucher_items" :key="item.id">
                <td>{{ item.type }}</td>
                <td>{{ item.tax }}</td>
                <td>{{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6">
        <AppCard :loading="pending" :title="$t('Files')">
          <table class="table table-hover">
            <thead>
              <tr>
                <!-- <th>Type</th>
                      <th>Tax</th>
                      <th>Amount</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in voucher.files" :key="item.id" />
            </tbody>
          </table>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default {
  name: 'ViewVoucher',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  data() {
    return {
      prevRoute: '/Vouchers',
    }
  },
  computed: {
    ...mapState('voucher', [
      'voucher',
      'pending',
    ]),
  },
  created() {
    this.getVoucher()
  },
  destroyed() {
    this.clearVoucher()
  },
  methods: {
    ...mapActions('voucher', [
      'getVoucher',
      'clearVoucher',
    ]),
  },
}
</script>
