import genders from './genders'
import roles from './roles'
import taxes from './taxes'
import tlds from './tlds'
import zones from './zones'

const intervals = [
  {
    value: 'days',
    text: 'days',
  },
  {
    value: 'weeks',
    text: 'weeks',
  },
  {
    value: 'months',
    text: 'months',
  },
  {
    value: 'years',
    text: 'years',
  },
]

export { roles, tlds, taxes, genders, intervals, }

export const dnsRecordTypes = zones.DNS_RECORD_TYPES
