<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Edit Category')" :prev-route="prevRoute" :loading="pending">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="name"
                      rules="required|max:50"
                      :counter="50"
                      label="Name"
                      placeholder="Enter Name"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="subscriptionstext"
                      label="Subscription Text"
                      name="subscriptionstext"
                      placeholder="Enter Subscription Text"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="invalid || pendingSave"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'EditCategory',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Categories')

    // store
    const pending = computed(_ => $store.state.category.pending)
    const pendingSave = computed(_ => $store.state.category.pendingSave)
    const errors = computed(_ => $store.state.category.errors)
    const editCategory = _ => $store.dispatch('category/editCategory')
    const getEditCategory = _ => $store.dispatch('category/getEditCategory')
    const clearCategory = _ => $store.dispatch('category/clearCategory')

    getEditCategory()

    const submitForm = _ => {
      if (pending.value) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editCategory().then(({ categorie, errors }) => {
        if (errors) {
          setForceRedirect(false)
          return
        }

        if (redirectDirect.value) {
          $router.push({
            name: 'categories-item',
            params: { id: categorie.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearCategory()
    })

    return {
      prevRoute,
      pending,
      pendingSave,
      errors,
      submitForm,
      ...mapFields('category', [
        'category.name',
        'category.subscriptionstext',
      ]),
    }
  },
})
</script>
