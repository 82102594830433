<template>
  <ItemWrapper>
    <v-row justify="center">
      <v-col cols="12" lg="6">
        <AppCard
          :title="`${$t('Category')} ${category.name || ''}`"
          card-text-classes="text-center"
          :prev-route="prevRoute"
          :loading="pending"
        >
          <div class="text--disabled">
            <p>
              <b>Subscription Text:</b><br />
              <span>{{ category.subscriptionstext }}</span>
            </p>

            <!-- TODO: fix endless circle redirect -->
            <!-- <p>
              <b>Actions:</b><br />
              <span>
                <router-link :to="{ name: 'categories-edit', params: { id: category.id } }" class="mx-1">
                  <v-btn class="pa-0" color="success" fab x-small dark>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </router-link>
              </span>
            </p> -->
          </div>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewCategory',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const prevRoute = ref('/Categories')

    // store
    const pending = computed(_ => $store.state.category.pending)
    const category = computed(_ => $store.state.category.category)
    const getCategory = _ => $store.dispatch('category/getCategory')
    const clearCategory = _ => $store.dispatch('category/clearCategory')

    getCategory()

    onBeforeUnmount(_ => {
      clearCategory()
    })

    return {
      prevRoute,
      pending,
      category,
    }
  },
})
</script>
