import axios from 'axios'
import store from '@/store'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

http.interceptors.request.use(config => {
  const { token } = store.state.auth

  if (token) {
    // set auth-header with token
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (!error.response.status) {
        store.commit('notifications/ADD_TOAST', {
          msg: 'Netzwerkfehler',
          type: 'error',
        })
      }
      if (error.response.status === 500) {
        store.commit('notifications/ADD_TOAST', {
          msg: 'Serverfehler',
          type: 'error',
        })
      }
      if (error.response.status === 401 && (![
        'users/logout',
        'users/login',
      ].includes(error.response.config.url))) {
        store.commit('notifications/ADD_TOAST', {
          msg: 'Authentifizierung fehlgeschlagen',
          type: 'error',
        })
      }
    }
    throw error
  },
)

export default http
