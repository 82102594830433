<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    nudge-top="15px"
  >
    <template #activator="{ on }">
      <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
        <v-text-field
          v-model="innerValue"
          slot-scope="{ errors, valid }"
          :error-messages="errors"
          :success="valid"
          v-bind="$attrs"
          v-on="on"
        />
      </ValidationProvider>
    </template>
    <v-date-picker v-model="innerValue" no-title scrollable />
  </v-menu>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'DatePickerWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(_ => props.value, newVal => innerValue.value = newVal)

    return {
      menu,
      innerValue,
    }
  },
})
</script>
