<template>
  <OverviewActions
    type="quotes"
    :item-id="quote.id"
    info
    :edit="quote.status === 'draft'"
    :remove="quote.status === 'draft'"
    :disable-remove="isQuotePending"
    @onRemove="removeQuote"
  />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'QuoteRowActions',
  components: {
    OverviewActions,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    pendingQuotes: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const isQuotePending = computed(_ => props.pendingQuotes.indexOf(props.quote.id) !== -1)

    const removeQuote = _ => {
      emit('onRemove')
    }

    return {
      isQuotePending,
      removeQuote,
    }
  },
})
</script>

