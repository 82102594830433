<template>
  <ItemWrapper>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <AppCard
          :loading="pending"
          :title="`${$t('User')} ${user.firstname} ${user.lastname}`"
          card-text-classes="text-center"
          :prev-route="prevRoute"
        >
          <template v-if="!pending">
            <v-avatar class="mx-auto d-block mb-4" size="130">
              <v-img src="https://picsum.photos/200/200" :alt="user.avatar" />
            </v-avatar>

            <h5 class="text-h6 category text-gray font-weight-light mb-3">
              {{ user.role }}
            </h5>
            <h3 class="card-title font-weight-light mb-3">
              {{ user.firstname }} {{ user.lastname }}
              <v-icon v-if="!user.active">mdi-account-lock</v-icon>
            </h3>
            <div class="text--disabled">
              <p>
                <b>Username</b><br />
                <span>{{ user.username }}</span>
              </p>
              <p>
                <b>E-Mail</b><br />
                <span>{{ user.email }}</span>
              </p>
              <p>
                <b>Client</b><br />
                <span>
                  <router-link
                    :to="{
                      name: 'clients-item',
                      params: { id: user.client.contact_handle.clients_id },
                    }"
                  >
                    <b v-if="user.client.contact_handle.organisation">
                      {{ user.client.contact_handle.organisation }}
                    </b>
                    {{ user.client.contact_handle.firstname }}
                    {{ user.client.contact_handle.lastname }}
                  </router-link>
                </span>
              </p>
              <!-- <td>{{ user.id }}</td> -->
              <!-- <td>{{ user.client_id }}</td> -->
              <!-- <td>{{ user.newsletter }}</td> -->
              <!-- <td>{{ user.tokencreated }}</td> -->
              <!-- <v-btn color="success" class="font-weight-light">Follow</v-btn> -->
            </div>
          </template>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewUser',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store } }) {
    const prevRoute = ref('/Users')

    // store
    const pending = computed(_ => $store.state.user.pending)
    const user = computed(_ => $store.state.user.user)
    const getUser = _ => $store.dispatch('user/getUser')
    const clearUser = _ => $store.dispatch('user/clearUser')

    getUser()

    onBeforeUnmount(_ => {
      clearUser()
    })

    return {
      prevRoute,
      pending,
      user,
    }
  },
})
</script>

