<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12" lg="6">
        <AppCard :title="$t('Create Product')" :prev-route="prevRoute" :loading="pending">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm($event))">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="number"
                      rules="required|min_value:1"
                      label="Number"
                      name="number"
                      type="number"
                      min="0"
                      step="1"
                      placeholder="Enter Number"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SearchSelectCategory v-model="categorie_id" rules="required" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="name"
                      rules="required"
                      label="Name"
                      name="name"
                      placeholder="Enter Name"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="period"
                      rules="required"
                      label="Period"
                      name="period"
                      placeholder="Enter Period"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="baseprice"
                      rules="required|min_value:0"
                      label="Price"
                      name="price"
                      placeholder="Enter Price"
                      type="number"
                      min="0"
                      step=".01"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="description"
                      label="Description"
                      name="description"
                      placeholder="Enter Description"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="subscriptionstext"
                      label="Subscription Text"
                      name="subscription-text"
                      placeholder="Enter Subscription Text"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="tax"
                      clearable
                      label="VAT"
                      :items="taxes"
                      menu-props="offset-y"
                      placeholder="Select Tax"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="invalid || pending">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
      <v-col cols="12" lg="6">
        <log-box :log="errors" />
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { taxes } from '@/config'
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import LogBox from '@/components/Test/LogBox'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectCategory from '@/components/Categories/SearchSelectCategory'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'

export default defineComponent({
  name: 'CreateProduct',
  components: {
    AppCard,
    ItemWrapper,
    LogBox,
    SearchSelectCategory,
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Products')

    // store
    const pending = computed(_ => $store.state.product.pending)
    const errors = computed(_ => $store.state.product.errors)
    const clearProduct = _ => $store.dispatch('product/clearProduct')
    const createProduct = _ => $store.dispatch('product/createProduct')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const submitForm = _ => {
      if (pending.vaue) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createProduct().then(({ product, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Produkt erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'products-item',
            params: { id: product.id },
          })
        } else {
          $router.push({ path: '/Products' })
        }
      })
    }

    onUnmounted(_ => {
      clearProduct()
    })

    return {
      prevRoute,
      taxes,
      pending,
      errors,
      submitForm,
      ...mapFields('product', [
        'product.number',
        'product.categorie_id',
        'product.name',
        'product.period',
        'product.baseprice',
        'product.description',
        'product.tax',
        'product.subscriptionstext',
      ]),
    }
  },
})
</script>
